export const liqudityPair=[
    {pid:1,
    layerId:0,
    lpSymbol:"BNB-BUSD",
    token0Symbol:"WBNB",
    token1Symbol:"BUSD",
    token0Address:'0x0dE8FCAE8421fc79B29adE9ffF97854a424Cad09',
    token1Address:'0xb8F320F62C7dF27fBB9a9050A6b75E3F01043912',
    token1:"https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xB8c77482e45F1F44dE1745F52C74426C631bDD52/logo.png",
    token2:"https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x4Fabb145d64652a948d72533023f6E7A623C7C53/logo.png",
    lpAddresses:"0x62f02096C6AC80552FdE1947eEEc314FfD225713",
    stakeContract:"0xe026BFe2c57562edAA0bEa0eF30527421900590e",    
    selector:"demo1",
    status:2
},
{pid:2,

    layerId:0,
    lpSymbol:"Flair-BUSD",
    token0Symbol:"Flair",
    token1Symbol:"BUSD",
    token0Address:'0x818283230fD35A2605F4830f6bE6d950508F1329',
    token1Address:'0xb8F320F62C7dF27fBB9a9050A6b75E3F01043912',
    token1:require('../../images/flair.png'),
    token2:"https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x4Fabb145d64652a948d72533023f6E7A623C7C53/logo.png",
    lpAddresses:"0xfAddd082c1f037Bc74Bf3096DaC3E3f5ad5B8109",
    stakeContract:"0x329Fd601851861fDBB32B527bfCfAC7aD5F4E365",
    selector:"demo2",
    status:1
},
{pid:3,
    layerId:0,
    lpSymbol:"Flair-TT1",
    token0Symbol:"Flair",
    token1Symbol:"TT1",
    token0Address:'0x818283230fD35A2605F4830f6bE6d950508F1329',
    token1Address:'0x9C6bD2e05c59B0B37594c7196c362C1F3ea2A2Fb',
    token1:require('../../images/flair.png'),
    token2:"https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x4Fabb145d64652a948d72533023f6E7A623C7C53/logo.png",
    lpAddresses:"0x7c1CfFA00c67bc1b49DcC947aCe933A46ae354AF",
    stakeContract:"0x7432A2d846949a706bC2836653b2DA7bDE896753",
    selector:"demo3",
    status:1
 },
 {
    pid:4,
    layerId:0,
    lpSymbol:"BNB-TT1",
    token0Symbol:"WBNB",
    token1Symbol:"TT1",
    token0Address:'0x0dE8FCAE8421fc79B29adE9ffF97854a424Cad09',
    token1Address:'0x9C6bD2e05c59B0B37594c7196c362C1F3ea2A2Fb',
    token1:require('../../images/flair.png'),
    token2:"https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x4Fabb145d64652a948d72533023f6E7A623C7C53/logo.png",
    lpAddresses:"0x219CaA6d78BD256F7e1325b2cFE0BDf7b72cC030",
    stakeContract:"0x57111146c02f238566bCFD658A2F6457363DD12d",
    selector:"demo4",
    status:1
 },
 
]