export const supportNetwork = { 
  56 : {
      name : "Binanace",
      chainId : 56,
      rpc : "https://bsc-dataseed1.ninicoin.io/",
      symbol : 'BNB'
  },
  // 97 : {
  //     name : "Binanace",
  //     chainId : 97,
  //     rpc : "https://data-seed-prebsc-1-s3.binance.org:8545/",
  //     symbol : 'BNB'
  // }
}

export const RPC_URLS = {
   56 : "https://bsc-dataseed1.ninicoin.io/",
  //  97 : "https://data-seed-prebsc-1-s3.binance.org:8545/"
};
