import { Modal } from 'react-bootstrap'
import React from 'react'
import './style.css'
export default function ErrorModal(props) {
    

    return (
        <>
        <Modal
        show={props.show}
        onHide={props.hide}
        className="token-modal-root"
        centered

      >
        <Modal.Header closeButton style={{borderBottom:"none"}}>
        </Modal.Header>
        <Modal.Body style={{ height: "280px" }}>
          <center>
          <div class="d-flex align-items-center justify-content-center StakeModal">
          <lord-icon src="https://cdn.lordicon.com/tdrtiskw.json"
            trigger="loop" delay="2000" colors="primary:#ffffff,secondary:#38ef7d"
            style={{width:"150px",height:"150px"}} >
          </lord-icon>
        </div>
        <div class="text-center" id="pre-confirmation">
          <h4 class="lh-base">{props.errorMsg} 
          </h4>
        </div>
        
          </center>
        </Modal.Body>
      </Modal>
        </>
    )
}
