import React from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/images/logo.png";
import { Container, Nav, Navbar } from "react-bootstrap";

const Header = () => {
  return (
    <>
      <Navbar collapseOnSelect expand="lg" bg="">
        <Container>
          <Link to="/" className="navbar-brand">
            <img src={Logo} />
          </Link>
          <Navbar>
            <Nav className="ms-auto">
              <Link to="/swap" className="nav-link">
                Launch App
              </Link>
            </Nav>
          </Navbar>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
