import { Modal } from 'react-bootstrap'
import React from 'react'

export default function SliipageModal(props) {
    

    return (
        <>
        <Modal
        show={props.show}
        onHide={props.hide}
        className="token-modal-root"
        centered

      >
        <Modal.Header closeButton style={{borderBottom:"none",width:"100%"}}>
        </Modal.Header>
        <Modal.Body style={{ height: "330px" ,width:"100%" }}>
          
          <h5>Setting</h5>
          <center>
          <div class="inner-content-box" style={{ height: "200px" }}>
            <h5>Slippage tolerance</h5>
            <div class="d-flex justify-content-end ms-5 ">
              <button type="button" name="button" class="btn btn-1 mx-2" onClick={()=>{props.setSlippage(1)}}>1%</button>
              <button type="button" name="button" class="btn btn-1 mx-2" onClick={()=>{props.setSlippage(1.5)}}>1.5%</button>
              <button type="button" name="button" class="btn btn-1 mx-2" onClick={()=>{props.setSlippage(2)}}>2%</button>
              <button type="button" name="button" class="btn btn-1 ms-2" onClick={()=>{props.setSlippage(2.5)}}>2.5%</button>
            </div>
            <div class="d-flex justify-content-center mt-5">
              <div class="fm-control">
                <input type="number" id="" onChange={(e) => props.setSlippage(e.target.value)} name="" value={props.value} placeholder="0.00%" />
              </div>
            </div>
          </div>
          <div class="mt-4 text-end">
            <button type="button" name="button" class="btn btn-1" onClick={props.hide}>Close</button>
          </div>
          </center>
        </Modal.Body>
      </Modal>  
        </>
    )
}
