

export const PermissionPoolTokenList =
[
 
    {
        "name": "Wrapped Ether",
        "value": "0x10ED43C718714eb63d5aA57B78B54704E256024E",
        "label": "WETH",
        "decimals": 18,
        "chainId": 42161,
        "logo": "https://raw.githubusercontent.com/sushiswap/icons/master/token/eth.jpg"
      },
      {
        "value": "0x55d398326f99059fF775485246999027B3197955",
        "chainId": 42161,
        "name": "Tether USD",
        "label": "USDT",
        "decimals": 6,
        "logo": "https://raw.githubusercontent.com/sushiswap/icons/master/token/usdt.jpg"
      },
      
    ]


      


