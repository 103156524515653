import './style.css'

import $ from 'jquery'
import { Modal } from 'react-bootstrap'
import React from 'react'

export default function TokenModal(props) {

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.hide}
        className="token-modal-root"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Select a token</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <input
              placeholder="Search name or mint address"
              onChange={props.handleSearchToken}
              type="search"
            />
            <div className="sort fs-container">
              <span className="title">Token name</span>
            </div>
          </div>
          {/* <div className='search__loader-modal'>
          <span class="loader"></span>
          </div> */}
          <div className="select-token">
            <ul>
              {props.children}
            </ul>
            <span className="token-text-hover">(Add Token)</span>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
