import React, { useContext, useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";

import $ from "jquery";
import { Link } from "react-router-dom";
import Logo from "../assets/images/logo.png";
import Wallet, { Connect } from "./Wallet/WalletConnect";
import { createContext } from "react";
import { disconnect } from "process";
import { ethers } from "ethers";
import { useLocation } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";

export const AddContext=createContext() 
const InnerHeader = (props) => {
  const {  account,error,chainId } =
    useWeb3React();
  //assigning location variable
  const location = useLocation();
  const { pathname } = location;
  const [srtadd, setSrtadd] = useState("Connect Wallet");
  const [wallet, setWallet] = useState(false);
  const[connected, setConnected]=useState(false)

  //const {isConnected}=useContext(WalletConnect)
  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");
  const sidebarToggle = () => {
    $("#sidebar").toggleClass("active");
    $(".overlay").addClass("active");
  };
  const dismiss = () => {
    $("#sidebar").removeClass("active");
    $(".overlay").removeClass("active");
  };

  //Connect Wallet

useEffect(()=>{
  if(connected===true){
      if(account===undefined){
        setWallet(false)
      setSrtadd("Connect Wallet")
      }
  }
  
  setConnected(false)
})

  return (
    <>
      <div onClick={(e) => e.stopPropagation()}>
        <nav id="sidebar">
          <ToastContainer />
          <div id="dismiss" onClick={dismiss}>
            <i className="fas fa-arrow-left"></i>
          </div>

          <div className="sidebar-header">
            <Link to="/" className="navbar-brand">
              <img src={Logo} />
            </Link>
          </div>

          <ul className="list-unstyled components">
            <li className={splitLocation[1] === "home" ? "active" : ""}>
              <Link to="/" className="nav-link">
                <span>
                  <i className="fas fa-home me-2"></i>
                  Home
                </span>
              </Link>
            </li>
            <li className={splitLocation[1] === "home" ? "active" : ""}>
            <a href="http://nft.hyperflair.io" className="nav-link" target="_blank">
                <span>
                  <i className="fal fa-image me-2"></i>
                  NFT Marketplace
                </span>
              </a>
            </li>
            <li className={splitLocation[1] === "swap" ? "active" : ""}>
              <Link to="/swap" className="nav-link">
                <span>
                  <i className="fal fa-exchange me-2"></i>
                  Swap
                </span>
              </Link>
            </li>
            <li className={splitLocation[1] === "liquidity" ? "active" : ""}>
              <Link to="/liquidity" className="nav-link">
                <span>
                  <i className="fal fa-hand-holding-water me-2"></i>
                  Liquidity
                </span>
              </Link>
            </li>
            <li className={splitLocation[1] === "pools" ? "active" : ""}>
              <Link to="/pools" className="nav-link">
                <span>
                  <i className="fas fa-th me-2"></i>
                  Pools
                </span>
              </Link>
            </li>
            <li className={splitLocation[1] === "farms" ? "active" : ""}>
              <Link to="/farms" className="nav-link">
                <span>
                  <i className="fal fa-wheat me-2"></i>
                  Farms
                </span>
              </Link>
            </li>
            <li className={splitLocation[1] === "staking" ? "active" : ""}>
              <Link to="/staking" className="nav-link">
                <span>
                  <i className="fal fa-lock me-2"></i>
                  Staking
                </span>
              </Link>
            </li>
          </ul>
        </nav>
        <div className="top-nav">
          <nav className="navbar navbar-expand-lg">
            <div className="container-fluid">
              <div className="d-inline-block d-lg-none">
                <Link to="/" className="navbar-brand">
                  <img src={Logo} />
                </Link>
              </div>
              <div className="connectWallet ms-auto">
               <Connect/>
                <div>

                </div>
              </div>
              <button
                type="button"
                id="sidebarCollapse"
                onClick={sidebarToggle}
                className="btn btn-primary d-inline-block d-lg-none ms-2"
              >
                <i className="fas fa-align-justify"></i>
              </button>
            </div>
          </nav>
        </div>
      </div>
      
    </>
  );
};

export default InnerHeader;
