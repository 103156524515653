import React, { useContext } from "react";
import { useWeb3React, UnsupportedChainIdError } from "@web3-react/core";
import { NoEthereumProviderError } from "@web3-react/injected-connector";
import { useEffect, useState } from "react";
import { injected, walletconnect, coinbaseWallet } from "./connectors";
import Modal from 'react-bootstrap/Modal';
import { DEFAULT_CHAIN } from './constants';
import useEagerConnect from './useWeb3';
import metamaskIcon from '../images/metamask.png';
import trustIcon from '../images/trust.png';
import coinbaseIcon from '../images/coinbase.png';
import walletconnectIcon from '../images/walletconnect.png';
import localStorage from "local-storage";
import { supportNetwork } from "./networks";
import { Context } from "../context/context";
import { ethers } from 'ethers'
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useAccount } from "wagmi";

export const Connect = function () {
  const { address } = useAccount();
  useEffect(() => {
    if (address) {
      localStorage.set('address', address);
      console.log(address)
    }
  }, [address])

  const switchNetwork = (networkid) => {
    try {
      // @ts-ignore
      window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: `0x${networkid.toString(16)}` }]
      });
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <React.Fragment>
        <>
<ConnectButton />
        </>
      
      {/* <Modal
        show={show}
        onHide={() => setShow(false)}
        className={`${darkMode ? 'dark-theme' : ''}`}
      >
        <Modal.Header closeButton>
          <Modal.Title>Connect to a wallet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="wallet__wrapper--modal-container">
            <p className="mt-0 mb-3">
              Connect with one of our available wallet providers or create a
              new one.
            </p>
            <p>Poppular</p>
            <div id="A" className="w-100">
              <button
                className="wallet__connect--button-container"
                onClick={() => {
                  activate(injected);
                  setShow(false);
                  setIstrust(false);
                }}
              >
                <span className="name svelte-ucnmri">MetaMask</span>
                <div className="">
                  <div className="">
                    <img
                      src={metamaskIcon}
                      alt="metamask img"
                      height="40px"
                      width="40px"
                    />
                  </div>
                </div>
              </button>
            </div>
            <div id="B" className="w-100">
              <button
                className="wallet__connect--button-container"
                onClick={() => {
                  activate(walletconnect);
                  setShow(false);
                  setIstrust(false);
                }}
              >
                <span className="name svelte-ucnmri">WalletConnect</span>
                <div className="">
                  <div className="">
                    <img
                      src={walletconnectIcon}
                      height="40px"
                      width="40px"
                      alt="binance img"
                    />
                  </div>
                </div>
              </button>
            </div>
            <div id="A" className="w-100">
              <button
                className="wallet__connect--button-container"
                onClick={() => {
                  activate(injected);
                  setShow(false);
                  setIstrust(true);
                }}
              >
                <span className="name svelte-ucnmri">TrustWallet</span>
                <div className="">
                  <div className="">
                    <img
                      src={trustIcon}
                      alt="metamask img"
                      height="40px"
                      width="40px"
                    />
                  </div>
                </div>
              </button>
            </div>
            <div id="B" className="w-100">
              <button
                className="wallet__connect--button-container"
                onClick={() => {
                  activate(coinbaseWallet);
                  setShow(false);
                  setIstrust(false);
                }}
              >
                <span className="name svelte-ucnmri">Coinbase</span>
                <div className="">
                  <div className="">
                    <img
                      src={coinbaseIcon}
                      height="40px"
                      width="40px"
                      alt="binance img"
                    />
                  </div>
                </div>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal> */}


    </React.Fragment >
  );
};

export default Connect;