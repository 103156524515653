

export const SolanaTokenList =
[
    
          {
            "name": "WBNB Token",
            "label": "WBNB",
            "value": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
            "chainId": 56,
            "decimals": 18,
            "logo": "https://pancakeswap.finance/images/tokens/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c.png"
          },
          {
            "name": "BUSD Token",
            "label": "BUSD",
            "value": "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
            "chainId": 56,
            "decimals": 18,
            "logo": "https://pancakeswap.finance/images/tokens/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56.png"
          },
          {
            "name": "Ethereum Token",
            "label": "ETH",
            "value": "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
            "chainId": 56,
            "decimals": 18,
            "logo": "https://pancakeswap.finance/images/tokens/0x2170Ed0880ac9A755fd29B2688956BD959F933F8.png"
          },
          {
            "name": "BTCB Token",
            "label": "BTCB",
            "value": "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
            "chainId": 56,
            "decimals": 18,
            "logo": "https://pancakeswap.finance/images/tokens/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c.png"
          },
          {
            "name": "Tether USD",
            "label": "USDT",
            "value": "0x55d398326f99059fF775485246999027B3197955",
            "chainId": 56,
            "decimals": 18,
            "logo": "https://pancakeswap.finance/images/tokens/0x55d398326f99059fF775485246999027B3197955.png"
          },
          {
            "name": "PancakeSwap Token",
            "label": "CAKE",
            "value": "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82",
            "chainId": 56,
            "decimals": 18,
            "logo": "https://pancakeswap.finance/images/tokens/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82.png"
          },
          {
            "name": "Venus",
            "label": "XVS",
            "value": "0xcF6BB5389c92Bdda8a3747Ddb454cB7a64626C63",
            "chainId": 56,
            "decimals": 18,
            "logo": "https://pancakeswap.finance/images/tokens/0xcF6BB5389c92Bdda8a3747Ddb454cB7a64626C63.png"
          },
          {
            "name": "VAI Stablecoin",
            "label": "VAI",
            "value": "0x4BD17003473389A42DAF6a0a729f6Fdb328BbBd7",
            "chainId": 56,
            "decimals": 18,
            "logo": "https://pancakeswap.finance/images/tokens/0x4BD17003473389A42DAF6a0a729f6Fdb328BbBd7.png"
          },
          {
            "name": "Pancake Bunny",
            "label": "BUNNY",
            "value": "0xC9849E6fdB743d08fAeE3E34dd2D1bc69EA11a51",
            "chainId": 56,
            "decimals": 18,
            "logo": "https://pancakeswap.finance/images/tokens/0xC9849E6fdB743d08fAeE3E34dd2D1bc69EA11a51.png"
          },
          {
            "name": "Alpaca",
            "label": "ALPACA",
            "value": "0x8F0528cE5eF7B51152A59745bEfDD91D97091d2F",
            "chainId": 56,
            "decimals": 18,
            "logo": "https://pancakeswap.finance/images/tokens/0x8F0528cE5eF7B51152A59745bEfDD91D97091d2F.png"
          },
          {
            "name": "Belt",
            "label": "BELT",
            "value": "0xE0e514c71282b6f4e823703a39374Cf58dc3eA4f",
            "chainId": 56,
            "decimals": 18,
            "logo": "https://pancakeswap.finance/images/tokens/0xE0e514c71282b6f4e823703a39374Cf58dc3eA4f.png"
          },
          {
            "name": "TokoCrypto",
            "label": "TKO",
            "value": "0x9f589e3eabe42ebC94A44727b3f3531C0c877809",
            "chainId": 56,
            "decimals": 18,
            "logo": "https://pancakeswap.finance/images/tokens/0x9f589e3eabe42ebC94A44727b3f3531C0c877809.png"
          },
          {
            "name": "Nerve Finance",
            "label": "NRV",
            "value": "0x42F6f551ae042cBe50C739158b4f0CAC0Edb9096",
            "chainId": 56,
            "decimals": 18,
            "logo": "https://pancakeswap.finance/images/tokens/0x42F6f551ae042cBe50C739158b4f0CAC0Edb9096.png"
          },
          {
            "name": "Ellipsis",
            "label": "EPS",
            "value": "0xA7f552078dcC247C2684336020c03648500C6d9F",
            "chainId": 56,
            "decimals": 18,
            "logo": "https://pancakeswap.finance/images/tokens/0xA7f552078dcC247C2684336020c03648500C6d9F.png"
          },
          {
            "name": "PancakeSwap Token",
            "label": "CAKE",
            "value": "0xa35062141Fa33BCA92Ce69FeD37D0E8908868AAe",
            "chainId": 97,
            "decimals": 18,
            "logo": "https://pancakeswap.finance/images/tokens/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82.png"
          },
          {
            "name": "BUSD Token",
            "label": "BUSD",
            "value": "0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee",
            "chainId": 97,
            "decimals": 18,
            "logo": "https://pancakeswap.finance/images/tokens/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56.png"
          }
    ]


      


