import "./style.css";

import { Modal } from "react-bootstrap";
import React from "react";

export default function TransactionModal(props) {
  return (
    <>
      <Modal
        show={props.show}
        onHide={props.hide}
        className="token-modal-root"
        centered
        style={{ bottomBorder: "none" }}
      >
        <Modal.Header
          className="pb-3"
          closeButton
          style={{ borderBottom: "none" }}
        >
          <Modal.Title className="fs-5"></Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "370px" }}>
          <div class="d-flex align-items-center justify-content-center">
            <lord-icon
              src="https://cdn.lordicon.com/lupuorrc.json"
              trigger="loop"
              delay="2000"
              colors="primary:#ffffff,secondary:#38ef7d"
              style={{ width: "150px", height: "150px" }}
            ></lord-icon>
          </div>
          <div class="text-center" id="pre-confirmation">
            <h3 className="lh-lg">Transaction Submitted</h3>
            <a
              // href={`https://bscscan.com/tx/${props?.tx.hash}`}
              href="/"
              class="btn btn-1"
              target="_blank"
            >
              View on Block Explorer <i class="fa-solid fa-angle-right"></i>
            </a>
            {/* <a href={`https://testnet.bscscan.com/tx/${props.tx.hash}`} class="btn btn-1" target="_blank">View on Block Explorer <i class="fa-solid fa-angle-right"></i></a>*/}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
