import "./App.css";
import React from 'react';
import { BrowserRouter, HashRouter, Route, Routes } from "react-router-dom";
import AddLiquidity from './components/AddLiquidity';
import ContactUs from "./components/Support";
import CreatePool from "./components/CreatePool";
import Farm from "./components/Farms";
import Home from "./components/Home";
import Liquidity from "./components/Liquidity";
import Pools from "./components/Pools";
import Staking from "./components/Staking";
import Swap from "./components/Swap";
import TermsOfServices from "./components/TermsOfService";
import "@rainbow-me/rainbowkit/styles.css";
import CommingSoon from "./components/CommingSoon/CommingSoon";
function App() {
  return (
    <>
      <div className="wrapper">
        <HashRouter>
          <Routes>
            <Route index element={<Home />} />
            <Route path="swap" element={<Swap />} />
            <Route path="liquidity" element={<Liquidity />} />
            <Route path="addliquidity" element={<AddLiquidity />} />
            <Route path="create" element={<CommingSoon />} />
            <Route path="pools" element={<CommingSoon />} />
            <Route path="farms" element={<CommingSoon />} />
            <Route path="staking" element={<Staking />} />
            <Route path="terms" element={<TermsOfServices />} />
            <Route path="support" element={<ContactUs />} />
          </Routes>
        </HashRouter>
      </div>
    </>
  );
}

export default App;
