import { Col, Container, Row } from 'react-bootstrap';

import { Link } from "react-router-dom";
import Logo from '../assets/images/logo.png'
import React from 'react'
import discord from '../assets/images/media-discord.svg'

const Footer = () => {
    return (
        <>
            <div className='footer-section'>
                <Container>
                    <Row>
                        <Col md={3} xs={6}>
                            <div className='footer-link'>
                                <div className='footer-title'>
                                    <h6>ABOUT</h6>
                                </div>
                                <ul className='footer-menu'>
                                    <li>
                                        <a href="https://hyperflair.medium.com/" target="_blank">Documentation</a>
                                    </li>
                                    <li>
                                        <a href="https://coinmarketcap.com/" target="_blank">CoinMarketCap</a>
                                    </li>
                                    <li>
                                        <a href="https://www.coingecko.com/" target="_blank">CoinGecko</a>
                                    </li>
                                    <li>
                                        <Link to="/terms">Disclaimer</Link>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col md={3} xs={6}>
                            <div className='footer-link'>
                                <div className='footer-title'>
                                    <h6>PROTOCOL</h6>
                                </div>
                                <ul className='footer-menu'>
                                    <li>
                                        <a href="https://tomocoins.xyz/nft/">Hyperflair NFT</a>
                                    </li>
                                    <li>
                                        <Link to="/staking">Hyperflair Stake</Link>
                                    </li>
                                    <li>
                                        <Link to="/pools">Hyperflair Pools</Link>
                                    </li>
                                    <li>
                                        <Link to="/swap">Hyperflair Swap</Link>
                                    </li>

                                </ul>
                            </div>
                        </Col>
                        <Col md={3} xs={6}>
                            <div className='footer-link'>
                                <div className='footer-title'>
                                    <h6>SUPPORT</h6>
                                </div>
                                <ul className='footer-menu'>
                                    <li>
                                        <a href="mailto:team@hyperflair.io">Hyperflair Support</a>
                                    </li>
                                    <li>
                                        <a href="https://hyperflair.medium.com/hyperflair-flair-white-paper-81c0a09f63bd" target="_blank">Hyperflair FAQ</a>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col md={3} xs={6}>
                            <div className='footer-link'>
                                <div className='footer-title'>
                                    <h6>RESOURCES</h6>
                                </div>
                                <div className='social-media'>
                                    <ul>
                                        <li><a href="https://twitter.com/Hyperflair_" target="_blank"><svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter" className="svg-inline--fa fa-twitter fa-w-16 fa-2x mt-2 mr-3 text-secondary icon-twitter" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path></svg>
                                            <span>Twitter</span>
                                        </a>
                                        </li>
                                        <li>
                                            <a href="https://discordapp.com/users/955604588508110959" target="_blank">
                                                <img src={discord} width="24px" />
                                                <span>Discord</span>
                                            </a>

                                        </li>
                                        <li>
                                            <a href="https://medium.com/@hyperflair" target="_blank">
                                                <svg width="50" height="30" fill="none" xmlns="https://www.w3.org/2000/svg" className="mr-3 mr-xl-0 text-secondary icon-medium" viewBox="0 0 50 30"><path d="M28.203 15.005c0 7.841-6.314 14.198-14.101 14.198C6.314 29.203 0 22.846 0 15.005S6.314.807 14.102.807s14.1 6.357 14.1 14.198zM43.672 15.005c0 7.38-3.156 13.367-7.05 13.367-3.895 0-7.051-5.986-7.051-13.367 0-7.381 3.156-13.367 7.05-13.367 3.894 0 7.05 5.984 7.05 13.367M50 15.005c0 6.611-1.11 11.974-2.48 11.974-1.37 0-2.48-5.361-2.48-11.974S46.152 3.03 47.52 3.03c1.37 0 2.48 5.361 2.48 11.975z" fill="currentColor"></path></svg>
                                                <span>Medium</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://t.me/hyperflair" target="_blank">
                                                <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="telegram-plane" className="svg-inline--fa fa-telegram-plane fa-w-14 fa-2x mt-2 mr-3 text-secondary icon-telegram" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M446.7 98.6l-67.6 318.8c-5.1 22.5-18.4 28.1-37.3 17.5l-103-75.9-49.7 47.8c-5.5 5.5-10.1 10.1-20.7 10.1l7.4-104.9 190.9-172.5c8.3-7.4-1.8-11.5-12.9-4.1L117.8 284 16.2 252.2c-22.1-6.9-22.5-22.1 4.6-32.7L418.2 66.4c18.4-6.9 34.5 4.1 28.5 32.2z"></path></svg>
                                                <span>Telegram</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='bottom-footer'>
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className="text-center">
                                <div className='footer-logo text-center'>
                                    <a href="#"><img src={Logo} /></a>
                                </div>
                                <span className="">
                                    Hyperflair.io © 2022
                                </span>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Footer;