import { Col, Container, Row } from "react-bootstrap";
import Footer from "./Footer";
import Header from "./Header";
import { Link } from "react-router-dom";
import React from "react";
import bell from '../assets/images/Icon_Bell.svg'
import chart from '../assets/images/chart.svg'
import protection from '../assets/images/Icon_Protection.png'
import selective from '../assets/images/Icon_Selective.svg'
import staking from '../assets/images/staking.png'
import time from '../assets/images/Icon_Time.svg'

const Home = () => {

   return (
      <>
         <Header />
         <section className="banner-area">
            <div className="text-center banner-img">
               <Container>
                  <div className="banner-text">
                     <div className="banner-title"><span className="common-color common-title">Hyperflair</span> <br />Built for the people</div>
                     <div className="banner-subtitle">Light speed <b>swaps</b>. High yield <b>returns</b>.<br /> Open API for <b>NFTs</b>.</div>
                     <div className="banner-btn">
                        <ul>
                           <li>
                              <Link to="/swap" className="btn btn-primary left-btn">Launch App</Link>
                           </li>
                           <li>
                              <a href="#" className="btn btn-primary right-btn">Launch ICO</a>
                           </li>
                        </ul>
                     </div>
                     <div className="d-flex justify-content-center">
                        <div className="left-value-box">
                           <div className="value-title">Total Value Locked</div>
                           <div className="value">
                              <div className="me-2">$</div>
                              <div >000</div>
                           </div>
                        </div>
                        <div className="right-value-box">
                           <div className="value-title">Total Trading Volume</div>
                           <div className="value">
                              <div className="me-2">$</div>
                              <div>000</div>
                           </div>
                        </div>
                     </div>
                     <div className="bulidon">
                        <a href="#">built on <span className="bulidon-text">Binance</span></a>
                     </div>
                  </div>
               </Container>
            </div>
         </section>
         <section className="feature-section">
            <Container>
               <Row>
                  <Col md={12}>
                     <h6 className="common-color">Our <span>Suite of Powerful</span> Features</h6>
                     <p>Empowering our users with the latest in Web3 functionality</p>
                  </Col>
               </Row>
               <Row>
                  <Col md={4} sm={12}>
                     <div className="feature-box">
                        <img src={protection} />
                        <h6>Secure System</h6>
                        <p>Certified, Audited and Built with Security and Trust</p>
                     </div>
                  </Col>
                  <Col md={4} sm={12}>
                     <div className="feature-box">
                        <img src={time} />
                        <h6>Superfast Onboard Swaps</h6>
                        <p>Lighting fast to maximise pricing and profits</p>
                     </div>
                  </Col>
                  <Col md={4} sm={12}>
                     <div className="feature-box">
                        <img src={chart} />
                        <h6>BUSD for Staking and Rebasing </h6>
                        <p>Pegged to the $US for passive income. Bonus BUSD on buybacks.</p>
                     </div>
                  </Col>
               </Row>
               <Row>
                  <Col md={4} sm={12}>
                     <div className="feature-box">
                        <img src={bell} />
                        <h6>New Listing Notifications</h6>
                        <p>Get early notifications on the hottest moonshot NFT’s</p>
                     </div>
                  </Col>
                  <Col md={4} sm={12}>
                     <div className="feature-box">
                        <img src={selective} />
                        <h6>Open API for NFT Trading </h6>
                        <p>Trade NFT’s with minimal gas and maximum returns on Binance</p>
                     </div>
                  </Col>
                  <Col md={4} sm={12}>
                     <div className="feature-box">
                        <img src={staking} />
                        <h6>Staking Pools and Yield Farming</h6>
                        <p>Staking and Farming to leverage high interest and income</p>
                     </div>
                  </Col>
               </Row>
            </Container>
         </section>

         <section className="roadmap-section ps-timeline-sec">
            <Container fluid>
               <Row>
                  <Col md={12}>
                     <h6 className="common-color">Hyperflair <span>Roadmap</span></h6>
                     <p className="roadmap-text">A comprehensive roadmap to drive user adoption.</p>
                  </Col>
               </Row>
               <Row>
                  <Col md={12}>
                     <ol className="ps-timeline">
                        <li>
                           <div className="img-handler-top">
                              2023
                           </div>
                           <div className="ps-bot">
                              <p>&#9989;&nbsp;UI/UX builder appointed</p>
                              <p>&#9989;&nbsp;Site build & testing</p>
                              <p>&#9989;&nbsp;V1 site completed</p>
                              <p>&#9989;&nbsp;Social sites setup</p>
                           </div>
                           <span className="ps-sp-bot">Q1</span>
                        </li>
                        <li>
                           <div className="img-handler-bot">
                              2024
                           </div>
                           <div className="ps-top">
                              <p>&#9989;&nbsp;Contract & Tokenomics</p>
                              <p>&#9989;&nbsp;Testing & Deployment</p>
                              <p>&#9989;&nbsp;Lightning swap completion</p>
                              <p>&#9989;&nbsp;Liquidity pools completion</p>
                              <p>&#9989;&nbsp;Staking completion</p>
                              <p>&#9989;&nbsp;Yield Farming completion</p>
                              <p>&#9989;&nbsp;NFT Marketplace completion</p>
                           </div>
                           <span className="ps-sp-top">Q2</span>
                        </li>

                        <li>
                           <div className="img-handler-top">
                              2024
                           </div>
                           <div className="ps-bot">
                              <p>&#9203;&nbsp;Marketing campaign</p>
                              <p>&#9203;&nbsp;NFT collections onboarded</p>
                              <p>&#9203;&nbsp;Contract audit</p>
                              <p>&#9203;&nbsp;Social media campaign</p>
                           </div>
                           <span className="ps-sp-bot">Q3</span>
                        </li>

                        <li>
                           <div className="img-handler-bot">
                              2024
                           </div>
                           <div className="ps-top">
                              <p>&#9203;&nbsp;Minor CEX listing</p>
                              <p>&#9203;&nbsp;Media release</p>
                              <p>&#9203;&nbsp;Initial Coin Offering</p>
                              <p>&#9203;&nbsp;NFT airdrop</p>
                           </div>
                           <span className="ps-sp-top">Q4</span>
                        </li>
                        <li>
                           <div className="img-handler-top">
                              2025
                           </div>
                           <div className="ps-bot">
                              <p>&#9203;&nbsp;Capital raise</p>
                              <p>&#9203;&nbsp;Investor onboarding</p>
                              <p>&#9203;&nbsp;Advertising campaign</p>
                              <p>&#9203;&nbsp;Major CEX listing</p>
                           </div>
                           <span className="ps-sp-bot">Q1</span>
                        </li>
                     </ol>
                  </Col>
               </Row>
            </Container>
         </section>
         <Footer />
      </>
   )
}
export default Home