import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import 'jquery/dist/jquery.slim.js';
import 'bootstrap/dist/js/bootstrap.min.js'
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import "./assets/css/min.css";
import './assets/css/style1.css'
import App from "./App";
import { CookiesProvider } from "react-cookie";
import React from "react";
import ReactDOM from "react-dom";
import { Web3ReactProvider } from "@web3-react/core";
import { ethers } from "ethers";
import reportWebVitals from "./reportWebVitals";
import { ContextProvider } from "./components/context/context";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import {
  bsc,
  
} from "wagmi/chains";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";
import { connectorsForWallets, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import {
  injectedWallet,
  rainbowWallet,
  metaMaskWallet,
  coinbaseWallet,
  walletConnectWallet,
} from "@rainbow-me/rainbowkit/wallets";

const getLibrary = (provider) => {
  const library = new ethers.providers.Web3Provider(provider);
  library.pollingInterval = 8000; // frequency provider is polling
  return library;
};
const projectId = "49c700b77be42c839bf61c394f2ffbce";
const { chains, publicClient } = configureChains(
  [
    
    bsc,
  
  ],
  [
    alchemyProvider({ apiKey: "-_JVGYKWnv2-gYfAtlRd04-w8Nfq761D" }),
    publicProvider(),
  ]
);

const connectors = connectorsForWallets([
  {
    groupName: "Recommended",
    wallets: [
      injectedWallet({ chains }),
      rainbowWallet({ projectId, chains }),
      metaMaskWallet({ projectId, chains }),
      coinbaseWallet({ chains, appName: "My RainbowKit App" }),
      walletConnectWallet({ projectId, chains }),
    ],
  },
]);
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
});
ReactDOM.render(
  <React.StrictMode>
    <Web3ReactProvider getLibrary={getLibrary}>
    <WagmiConfig config={wagmiConfig}>
      <CookiesProvider>
        <ContextProvider>
        <RainbowKitProvider chains={chains}>
          <App />
          </RainbowKitProvider>
        </ContextProvider>
      </CookiesProvider>
      </WagmiConfig>
    </Web3ReactProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
