import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import CancelStakeModal from "../Modals/CancelStakeModal";
import WaitingModal from "../Modals/WaitingModal";
import ErrorModal from "../Modals/ErrorModal";
import TransactionModal from "../Modals/TransactionModal";
import Connect from "../Wallet/WalletConnect";
import { useAccount } from "wagmi";

const StakingCardPrimary = () => {
  const { address } = useAccount();
  const [stakeDetailsModal, setStakeDetailsModal] = useState(false);
  const [cancelStake, setCancelStake] = useState(false);
  const [stakeModal, setStakeModal] = useState(false);
  const [wait, setWait] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [errorModalMessage, setErrorModalMessage] = useState("");
  const [transaction, setTransaction] = useState(false);
  const [harvestModal, setHarvestModal] = useState(false);

  const waitModelHide = () => {
    setWait(false);
  };
  const errorModalHide = () => {
    setErrorModal(false);
  };
  const handleTransactionHide = () => {
    setTransaction(false);
  };

  const calRewards = () => {
    console.log("calRewards");
  };
  return (
    <div style={{ overflowY: "auto" }}>
      <div className="staking_card">
        <div className="card-inner">
          <div className="card-top">
            <div className="d-flex align-items-center gap-2">
              <img
                src={require("../../assets/images/logo.png")}
                alt=""
                className="img-fluid"
              />
              <h4 className="m-0">Hyperflair20</h4>
            </div>
            <span className="card-typeBadge">Stake</span>
          </div>
          <div className="card-content">
            <ul className="card-ul">
              <li>
                <p>Staking APY</p>
                <div>20% P.A.</div>
              </li>
              <li>
                <div>
                  <p>Staked Hyperflair</p>
                  <h5>0</h5>
                </div>

                <div className="d-flex align-items-center gap-2">
                  <button
                    type="button"
                    className="btn btn-harvest"
                    style={{ fontSize: "12px" }}
                    onClick={() => setStakeDetailsModal(true)}
                  >
                    Staking Data
                  </button>
                </div>
              </li>
              <li>
                <div>
                  <p>Earned Hyperflair:</p>
                  <h5>0</h5>
                </div>
                <div>
                  <button
                    type="button"
                    className="btn btn-harvest"
                    onClick={() => {
                      setHarvestModal(true);
                    }}
                  >
                    Harvest
                  </button>
                </div>
              </li>
            </ul>
            <div className="card-button my-4">
              {address ? (
                <div className="row g-2">
                  <div className="col-sm-12 col-md-6">
                    {" "}
                    <button
                      type="button"
                      className="btn"
                      onClick={() => setStakeModal(true)}
                    >
                      Stake
                    </button>{" "}
                  </div>
                  <div className="col-sm-12 col-md-6">
                    {" "}
                    <button
                      type="button"
                      className="btn"
                      disabled
                      onClick={() => setCancelStake(true)}
                    >
                      Unstake
                    </button>{" "}
                  </div>
                </div>
              ) : (
                <Connect />
              )}
            </div>
          </div>
        </div>
      </div>
      <CancelStakeModal show={cancelStake} setShow={setCancelStake} />
      {/* STAKE INPUT MODAL START */}
      <Modal centered show={stakeModal} onHide={() => setStakeModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title className="fs-5 text-center mx-auto">
            Stake Hyperflair
          </Modal.Title>
          <div
            className="modal-close-btn btn btn-secondary"
            onClick={() => setStakeModal(false)}
          >
            X
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <div className="my-4">
              <p>Your Hyperflair Balance</p>
              <h4 className="fw-bold my-1">0</h4>
              {/* <p className="text-xs text-secondary">~$0.0000</p> */}
            </div>
            <label htmlFor="">Enter Hyperflair Amount to Stake</label>
            <input
              type="number"
              name="amt"
              id="value"
              placeholder="10000"
              value=""
            />
          </div>

          <div className="mt-4 d-flex align-items-center">
            <button className="btn btn-success w-100">Stake Now</button>
          </div>
        </Modal.Body>
      </Modal>
      <WaitingModal show={wait} hide={waitModelHide} />
      <TransactionModal
        show={transaction}
        hide={handleTransactionHide}
        // tx={tx}
      />
      <ErrorModal
        show={errorModal}
        hide={errorModalHide}
        errorMsg={errorModalMessage.slice(0, 30)}
      />
      {/* STAKE INPUT MODAL END */}
      {/* HARVEST MODAL START */}
      <Modal centered show={harvestModal} onHide={() => setHarvestModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title className="fs-5 text-center mx-auto">
            Harvest Hyperflair
          </Modal.Title>
          <div
            className="modal-close-btn btn btn-secondary"
            onClick={() => setHarvestModal(false)}
          >
            X
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-sm-12 col-md-12 text-center">
              <div className="harvestModalSidebar">
                <div className="mt-5">
                  <div className="my-4">
                    <p>Claimable Rewards</p>
                    <h4 className="fw-bold my-1">0</h4>
                  </div>
                  <button
                    className="btn btn-success w-100"
                    onClick={calRewards}
                  >
                    Claim Rewards
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* HARVEST MODAL END */}

      {/* STAKE DETAILS MODAL START */}
      <Modal
        centered
        show={stakeDetailsModal}
        onHide={() => setStakeDetailsModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title className="fs-5 text-center mx-auto">
            Staked Hyperflair Data
          </Modal.Title>
          <div
            className="modal-close-btn btn btn-secondary"
            onClick={() => setStakeDetailsModal(false)}
          >
            X
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-sm-12 col-md-12">
              <ul className="d-flex flex-column gap-2">
                <li className="d-flex align-items-center justify-content-between gap-2">
                  <div className="d-block">Hyperflair Staking Slot</div>
                  <div className="d-block">
                    <select name="" id="" className="sm_select_list">
                      <option value="0">1</option>
                      <option value="1">2</option>
                      <option value="2">3</option>
                      <option value="3">4</option>
                      <option value="4">5</option>
                      <option value="5">6</option>
                      <option value="6">7</option>
                      <option value="7">8</option>
                      <option value="8">9</option>
                      <option value="9">10</option>
                    </select>
                  </div>
                </li>
                <li className="d-flex align-items-center justify-content-between gap-2">
                  <div className="d-block">Staked Hyperflair</div>
                  <div className="d-block">0</div>
                </li>
                <li className="d-flex align-items-center justify-content-between gap-2">
                  <div className="d-block">Lockup Period</div>
                  <div className="d-block">12 Months</div>
                </li>
                <li className="d-flex align-items-center justify-content-between gap-2">
                  <div className="d-block">Unlock Time</div>
                  <div className="d-block">12:00</div>
                </li>
              </ul>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* STAKE DETAILS MODAL END */}
    </div>
  );
};

export default StakingCardPrimary;
