import React from 'react'
import { Modal } from 'react-bootstrap'

const CancelStakeModal = ({show, setShow}) => {
  return (
    <Modal centered show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title className="fs-5 text-center mx-auto">Stake Modal</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='form-group'>
          <div className="my-4">
            <p>Your Hyperflair Balance</p>
            <h4 className="fw-bold my-1">00</h4>
            <p className="text-xs text-secondary">~$0.0000</p>
          </div>
          <label htmlFor="">Amount Of Hyperflair</label>
          <input type="number" name="amt" id="value" placeholder="10000" />
        </div>
        <div className='mt-4 d-flex align-items-center justify-content-end gap-2'>
          <button className='btn btn-secondary' onClick={() => setShow(false)}>Cancel</button>
          <button className='btn btn-success' 
        //   onClick={stake}
          >Stake</button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default CancelStakeModal