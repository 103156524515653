import "react-toastify/dist/ReactToastify.css";

import { Col, Container, Modal, Row } from "react-bootstrap";
import {
  ERC20ABI,
  WBNBCOIN,
  tokenLogo,
} from "../assets/Constant/DefaultValues";
import {
  PANCAKE_ROUTER,
  PANCAKE_ROUTER_abi,
} from "../assets/Constant/Contract/RouterContract";
import {
  PairTokenAbi,
  pair_abi,
  pair_contract,
} from "../assets/Constant/Contract/FactoryContract";
import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { Connect } from "../components/Wallet/WalletConnect";
import { AiFillSetting } from "react-icons/ai";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { Link } from "react-router-dom";
import { PermissionPoolTokenList } from "../assets/Constant/Tokens/PermissionPoolTokens";
import { SolanaTokenList } from "../assets/Constant/Tokens/SolanaTokens";
import Web3 from "web3";
import Web3Modal from "web3modal";
import { ethers } from "ethers";
import { getBaseTokenLogoURLByTokenSymbol } from "../assets/Constant/Tokens/Token";
import { rpcUrl } from "../assets/Constant/DefaultValues";
import { useCookies } from "react-cookie";
import { useWeb3React } from "@web3-react/core";
import TokenModal from "./Modals/TokenModal";
import TransactionModal from "./Modals/TransactionModal";
import WaitingModal from "./Modals/WaitingModal";
import ConfirmModalSwap from "./Modals/ConfirmModalSwap";
import ErrorModal from "./Modals/ErrorModal";
import InnerHeader from "./Inner-Header";
import SliipageModal from "./Modals/SliipageModal";
import { useAccount } from "wagmi";
//----------------------------------------------------------------------------------------//
const Swap = () => {
  const { address } = useAccount();

  /*search modal*/
  const [search, setSearch] = useState("");

  /*token modal*/
  const [token1, setToken1] = useState(false);
  const handleToken1 = () => {
    setToken1(false);
    cookieAddList();
  };
  const handletoken1 = () => setToken1(true);

  const [token2, setToken2] = useState(false);
  const handleToken2 = () => {
    setToken2(false);
    cookieAddList();
  };

  const handletoken2 = () => setToken2(true);

  /*token list modal*/
  const [tokenlist, setTokenList] = useState(false);
  const handleTokenList = () => setTokenList(false);

  //Token For Pairing
  const [coin1, setCoin1] = useState(WBNBCOIN);
  const [coin2, setCoin2] = useState("");

  //Token Label
  const [lbl1, setLbl1] = useState(getBaseTokenLogoURLByTokenSymbol[0].label);

  const [lbl2, setLbl2] = useState("Select a currency");
  // smartcontractt
  var web3 = new Web3(rpcUrl);
  const router_contract = new web3.eth.Contract(
    JSON.parse(PANCAKE_ROUTER_abi),
    PANCAKE_ROUTER
  );

  //Token Logo
  const [logoimg1, setLogoImg1] = useState(
    getBaseTokenLogoURLByTokenSymbol[0].logo
  );
  const [logoimg2, setLogoImg2] = useState(tokenLogo);

  //Amount In
  const [amountIn, setAmountIn] = useState("");

  //Amount Out
  const [amountOut, setAmountOut] = useState("");

  //Factory Contract
  const [factoryContract, setFactoryContract] = useState(null);

  //Router Contract
  const [routerContract, setRouterContract] = useState();

  //PriceImpact
  const [priceImpact, setPriceImpact] = useState(0);

  //decimal token1
  const [decimal1, setDecimal1] = useState(18);

  //decimal token2
  const [decimal2, setDecimal2] = useState(18);

  //Token List
  const [defaultoption, setDefaultOptions] = useState(
    getBaseTokenLogoURLByTokenSymbol
  );

  //Wallet Connect
  const [wallet, setWallet] = useState(false);

  //Wallet msg
  const [walletMsg, setWalletMsg] = useState("Connect Wallet");

  //Pair Address
  const [pairAddress, setPairAddress] = useState(
    "0x62f02096C6AC80552FdE1947eEEc314FfD225713"
  );

  //Confirm Model
  const [confirm, setConfirm] = useState(false);
  const handleConfirm = () => setConfirm(true);
  const handleConfirmHide = () => {
    setConfirm(false);
  };

  //Confirm Model
  const [transaction, setTransaction] = useState(false);
  const handleTransaction = () => {
    setTransaction(true);
    waitModelHide();
  };
  const handleTransactionHide = () => setTransaction(false);

  //Error Msg
  const [errorMsg, setErrorMsg] = useState("");
  //Error Msg Model
  const [errShow, setErrShow] = useState(false);
  const handleErrorMsg = () => setErrShow(true);
  const handleErrorMsgHide = () => setErrShow(false);

  //Cookie
  const [cookies, setCookie] = useCookies(["newTokenList"]);

  //if address(No wallet or wallet is not Connected) doesnt exist

  const [walletConnect, setWallectConnect] = useState(false);

  /* Exchange */
  const [exchange, setExcahnge] = useState(false);

  //Balance Of Tokens
  const [coin1Balance, setCoin1Balance] = useState("-");
  const [coin2Balance, setCoin2Balance] = useState("-");

  //Slippage
  const [slippage, setSlippage] = useState(1.5);
  //To Calculate Slippage
  const [showSlippage, setShowSlippage] = useState(false);
  const handleShowSlippage = () => setShowSlippage(true);
  const CloseSlippage = () => setShowSlippage(false);

  //pairContract Balance
  const [balance, setBalance] = useState();

  //amountOutMin
  const [amoutOutmin, setAmoutOutMin] = useState();

  //Deafult TokenList Option

  //newToken
  const [newToken, setNEwToken] = useState({});

  //Amount Min Recieved
  const [amountMinRec, setAmountMinRec] = useState();

  //Price per Token
  const [pricePerTOken, setPricePerToken] = useState(0);
  const [tx, setTx] = useState("");

  //token type
  const [type1, setType1] = useState("coin");
  const [type2, setType2] = useState("token");

  //Wait Model

  const [wait, setWait] = useState(false);
  const waitModel = () => {
    setWait(true);
    handleConfirmHide();
  };
  const waitModelHide = () => setWait(false);
  useEffect(() => {
    setSearch("");
  }, [coin1, coin2]);
  //++++++++++++++++++++++++++++++++++++++++++ Handling Events ++++++++++++++++++++++++++++++++++++++++++++++=+++++//

  //Contract Intialization On first Load

  useEffect(() => {
    var web3 = new Web3(rpcUrl);
    const router_contract = new web3.eth.Contract(
      JSON.parse(PANCAKE_ROUTER_abi),
      PANCAKE_ROUTER
    );
    setRouterContract(router_contract);
    const factory_contract = new web3.eth.Contract(pair_abi, pair_contract);
    setFactoryContract(factory_contract);
  }, []);

  //Wallet Connect
  // useEffect(() => {
  //   const getProvider = () => {
  //     const type = window.localStorage.getItem("provider");
  //     if (type) {
  //       if (chainId === 56) {
  //         setWalletMsg("Swap");
  //         setWallet(false);
  //       } else {
  //         setWalletMsg("Wrong Network");
  //         setWallet(false);
  //       }
  //     }
  //   };
  //   if (walletConnect === true) {
  //     getProvider();
  //     setWallet(false);
  //   }
  // }, [activate, active, library, connector, walletConnect]);

  //Wallet Msg
  useEffect(() => {
    if (address == undefined) {
      setWalletMsg("Connect Wallet");
    } else {
      if (amountIn == "") {
        setWalletMsg("Enter Amount");
        setAmountOut("");
      } else if (amountOut == "") {
        setWalletMsg("Insufficient Liquidity Funds");
      } else if (parseFloat(coin1Balance) < amountIn) {
        setWalletMsg(`Insufficient  ${lbl1} Balance`);
      } else {
        setWalletMsg("Swap");
      }
    }
  }, [address, amountIn, amountOut]);
  useEffect(() => {
    if (walletMsg === true) setWallet(false);
  }, [walletMsg]);
  //-----------------------AmountIn CHange Handler---------------------------------//
  const [amtIn, setAmtIn] = useState("");
  const [amtOut, setAmtOut] = useState("");
  const handleChnageAmount = (e) => {
    const value = e.target.value;
    setAmtIn(value);
    setAmountIn(value);
  };
  const handleChnageAmount2 = (e) => {
    const value = e.target.value;
    setAmtOut(value);
    setAmountOut(value);
  };
  useEffect(() => {
    if (amountOut === "") {
      setAmountIn("");
    }
  }, [amountOut]);

  useEffect(() => {
    if (amtIn === "") {
      setAmountOut("");
    } else {
      getAmountsOut();
    }
  }, [amtIn, lbl1, lbl2]);

  useEffect(() => {
    if (amtOut === "") {
      setAmountIn("");
    } else {
      getAmountsIn();
    }
  }, [amtOut]);

  //++++++++++++++++++++++++++++++++++++++++++++++++ Function Calls +++++++++++++++++++++++++++++++++++++++++++++++++//

  //Coin1 Balance calculation if Coin2 is a Token
  const getTokenBalance1 = async () => {
    try {
      if (type1 === undefined || type1 === null || type1 === "") {
        if (coin1 != "") {
          setType1("token");
        }
      }
      if (type1 === "coin") {
        CoinBalance();
      }
      if (type1 === type2) {
        setCoin1Balance("-");
      }
      const web3Modal = new Web3Modal();
      const connection = await web3Modal.connect();
      const provider = new ethers.providers.Web3Provider(connection);
      var web3 = new Web3(provider);
      const coin1Contract = new ethers.Contract(coin1, ERC20ABI, provider);
      if (type1 === "token") {
        setCoin1Balance("-");
        if (lbl1 === "Select a currency") {
          // setCoin1Balance("-");
          // setCoin1("");
        } else {
          console.info("before balance");
          let coin1Balance = await coin1Contract.balanceOf(address);
          console.info("after balance");
          coin1Balance = ethers.utils.formatUnits(coin1Balance, decimal1);
          coin1Balance = parseFloat(coin1Balance).toFixed(2);
          console.info(coin1Balance);
          setCoin1Balance(coin1Balance);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  //Coin2 Balance calculation if Coin2 is a Token

  const getTokenBalance2 = async () => {
    try {
      if (type2 === undefined || type2 === null || type2 === "") {
        if (coin2 != "") {
          setType2("token");
        }
      }
      if (type2 === "coin") {
        CoinBalance();
      }
      const web3Modal = new Web3Modal();
      const connection = await web3Modal.connect();
      const provider = new ethers.providers.Web3Provider(connection);
      var web3 = new Web3(provider);
      const coin2Contract = new ethers.Contract(coin2, ERC20ABI, provider);
      const address1 = address;
      if (type2 === "token") {
        if (lbl2 === "Select a currency") {
          setCoin2Balance("-");
          setCoin2("");
        } else {
          let coin2Balance = await coin2Contract.balanceOf(address1);

          coin2Balance = ethers.utils.formatUnits(coin2Balance, decimal2);
          coin2Balance = parseFloat(coin2Balance).toFixed(2);
          setCoin2Balance(coin2Balance);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const CoinBalance = async () => {
    console.log("hy");
    const web3Modal = new Web3Modal();
    const connection = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(connection);
    var web3 = new Web3(rpcUrl);
    var balance = await web3.eth.getBalance(address);
    let bal = ethers.utils.formatEther(balance);
    bal = parseFloat(bal).toFixed(2);
    if (type1 === "coin") {
      if (lbl1 === "Select a currency") {
        setCoin1Balance("-");
        setCoin1("");
      } else {
        setCoin1Balance(bal);
        setCoin1(WBNBCOIN);
      }
    }
    if (type2 === "coin") {
      if (lbl2 === "Select a currency") {
        setCoin2Balance("-");
        setCoin2("");
      } else {
        setCoin2Balance(bal);
        setCoin2(WBNBCOIN);
      }
    }
  };

  useEffect(() => {
    if (address) {
      getTokenBalance1();
    }
  }, [coin1, address]);

  useEffect(() => {
    if (address) {
      getTokenBalance2();
    }
  }, [coin2, address]);

  // useEffect(() => {
  //   if (lbl1 === lbl2) {
  //     setLbl2("Select a currency");
  //     setCoin2("");
  //     setType2("");
  //     CoinBalance();
  //     setCoin2Balance("-");
  //   }
  // }, [lbl1]);

  // useEffect(() => {
  //   if (lbl2 === lbl1) {
  //     setLbl1("Select a currency");
  //     setCoin1("");
  //     setType1("");
  //     CoinBalance();
  //     setCoin1Balance("-");
  //     setLogoImg1("");
  //   }
  // }, [lbl2]);

  //-------------------------------------------+ Exchange +-------------------------------------------------//

  const handleExchange = () => {
    setExcahnge(!exchange);
    let tempCoin1 = coin1;
    let tempCoin2 = coin2;
    setCoin1(tempCoin2);
    setCoin2(tempCoin1);

    let templbl1 = lbl1;
    let templbl2 = lbl2;
    setLbl1(templbl2);
    setLbl2(templbl1);

    let logo1 = logoimg1;
    let logo2 = logoimg2;
    setLogoImg1(logo2);
    setLogoImg2(logo1);

    let tempAmtIn = amountIn;
    let TempAmtOut = amountOut;
    setAmountIn(TempAmtOut);
    setAmountOut(tempAmtIn);

    let tempType1 = type1;
    let tempType2 = type2;
    setType1(tempType2);
    setType2(tempType1);

    let TokenBalance = coin2Balance;
    let CoinBalance = coin1Balance;
    setCoin1Balance(TokenBalance);
    setCoin2Balance(CoinBalance);
  };
  //--------------------------------------------------+ Max Button +-------------------------------------//
  const max1 = () => {
    setAmountIn(coin1Balance);
    setAmtIn(coin1Balance);
    getAmountsOut();
  };
  const max2 = () => {
    setAmountOut(coin2Balance);
    setAmtOut(coin2Balance);
    getAmountsIn();
  };
  //--------------------------------------+ getAmountsOut +-------------------------------------------//
  // const getAmountsOut = async () => {
  //   try {
  //     let coin1amountIn = ethers.utils.parseUnits(amountIn.toString(), decimal1); // amount in for coin 1
  //     let path = [coin1, coin2];
  //     if (type1 === type2) {
  //       path = [coin1, "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c", coin2];
  //     }

  //     let amounts = await router_contract.methods
  //       .getAmountsOut(coin1amountIn.toString(), path)
  //       .call();

  //     let AmountsCvt = parseFloat(ethers.utils.formatUnits(amounts[1], decimal2)).toFixed(4); // amountOut

  //     if (amountIn === "") {
  //       setAmountOut("");
  //     } else {
  //       setAmountOut(AmountsCvt); // AmountOut
  //     }

  //     // Calculate AmountOutMin
  //     let AmountOutMinCal = ethers.BigNumber.from(amounts[1]).sub(
  //       ethers.BigNumber.from(amounts[1]).mul(slippage).div(100)
  //     );

  //     let AmountOutMin = AmountOutMinCal.toString();
  //     let AmountOutMinRec = parseFloat(ethers.utils.formatUnits(AmountOutMin, decimal2)).toFixed(4); // amountOut
  // console.log({AmountOutMin});
  //     setAmoutOutMin(AmountOutMin);
  //     console.log({AmountOutMinRec});
  //     setAmountMinRec(AmountOutMinRec);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  const getAmountsOut = async () => {
    try {
      // let coin1amountIn = Math.floor(
      //   amountIn * Math.pow(10, decimal1.toString())
      // );
      let coin1amountIn = ethers.utils.parseUnits(
        amountIn.toString(),
        decimal1.toString()
      );
      let path = [coin1, coin2];
      // if (type1 === type2) {
      //   path = [coin1, "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c", coin2];
      // }

      let amounts = await router_contract.methods
        .getAmountsOut(coin1amountIn.toString(), path)
        .call();
      let AmountsCvt = parseFloat(
        amounts[1].toString() / Math.pow(10, decimal2.toString())
      ).toFixed(7); //amoutOut

      if (amountIn === 0) {
        setAmountOut(0);
      } else {
        setAmountOut(AmountsCvt); //AmountOut
      }

      //--------------------------------------+ AmountOutMin +------------------------------------------//
      let AmountOutMinCal =
        amounts[1].toString() - amounts[1].toString() * (slippage / 100);
      let AmountOutMin = parseInt(AmountOutMinCal);
      let AmountOutMinRec = parseFloat(
        AmountOutMinCal / Math.pow(10, decimal2.toString())
      ).toFixed(7); //amoutOut
      setAmoutOutMin(AmountOutMin);
      setAmountMinRec(AmountOutMinRec);
    } catch (err) {
      console.log(err);
      setAmountOut(0);
      setAmoutOutMin(0);
      setAmountMinRec(0);
    }
  };

  //--------------------------------------+ getAmountsIn +-------------------------------------------//
  const [amountTokenVal, setAmountTokenVal] = useState(0);
  const getAmountsIn = async () => {
    try {
      let coin2amountOut = ethers.utils.parseUnits(
        amountOut,
        decimal1.toString()
      ); //amount in for coin 1
      let amounts = await routerContract.methods
        .getAmountsIn(coin2amountOut, [coin1, coin2])
        .call();
      let AmountsCvt = parseFloat(amounts[0] / Math.pow(10, decimal2)).toFixed(
        4
      ); //amountIn
      if (amountOut === 0) {
        setAmountIn(0);
      } else {
        setAmountIn(AmountsCvt);
        setAmountTokenVal(amounts[1]);
      } //AmountOut

      //--------------------------------------+ AmountOutMin +------------------------------------------//
      let AmountOutMinCal = amounts[1] - amounts[1] * (slippage / 100);
      let AmountOutMin = parseInt(AmountOutMinCal);

      setAmoutOutMin(AmountOutMin);
      let AmountOutMinRec = parseFloat(
        AmountOutMinCal / Math.pow(10, decimal2)
      ).toFixed(4);
      setAmountMinRec(AmountOutMinRec);
    } catch (err) {
      console.log(err);
      setAmountIn(0);
        setAmountTokenVal(0);
        setAmountMinRec(0);
    }
  };

  //----------------------------------------+ perTokenPrice +---------------------------------------------//
  //--Per Token Price --//
  useEffect(async () => {
    let coin1Price = ethers.utils.parseUnits("1", decimal1);
    let price = await router_contract.methods
      .getAmountsOut(coin1Price, [coin1, coin2])
      .call();
    //--per Token Price--//
    // console.log(price);
    let PriceOut = ethers.utils.formatUnits(price[1], decimal2);
    let AmountsOutPrice = parseFloat(PriceOut).toFixed(3); //Price
    setPricePerToken(AmountsOutPrice);
  }, [amountIn]);
  //---------------------------------------------+ Adding New Token +--------------------------------------------------------------//
  //---------------------------------------------+ Adding New Token +--------------------------------------------------------------//
  //---Add New Token--//
  useEffect(() => {
    const addToken = async () => {
      if (defaultoption.length === 0) {
        //console.log("defaultOptionList",defaultoption )
        try {
          const web3Modal = new Web3Modal();
          const connection = await web3Modal.connect();
          const provider = new ethers.providers.Web3Provider(connection);
          //console.log("provider",provider)
          const web3 = new Web3(provider);
          const new_token = web3.utils.toChecksumAddress(search);
          // console.log(new_token)
          const Contract_new_token = new ethers.Contract(
            new_token,
            ERC20ABI,
            provider
          );
          const decimal = await Contract_new_token.decimals();
          const Label = await Contract_new_token.symbol();
          const name = await Contract_new_token.name();
          const newTokenAdd = {
            value: new_token,
            name: name,
            label: Label,
            decimals: decimal,
            logo: tokenLogo,
            type: "token",
          };
          setNEwToken(newTokenAdd);
          let newTokenArr = [];
          newTokenArr.push({
            value: new_token,
            name: name,
            label: Label,
            decimals: decimal,
            logo: tokenLogo,
            type: "token",
            button: (
              <input
                type="button"
                value="import"
                style={{ backgraoundColor: "red" }}
                onClick={cookieAdd}
              />
            ),
          });
          setDefaultOptions(newTokenArr);
          const json_arr = JSON.stringify(newToken);
        } catch (err) {}
      }
    };
    addToken();
  }, []);
  //Cookie Add
  const cookieAdd = () => {
    cookieAddList();
    let newCookieList = [];
    let cookieLen = document.cookie.indexOf("newTokenList=");
    let cookie = cookies.newTokenList;
    if (cookieLen === -1) {
      newCookieList.push(newToken);
    } else if (cookie == null) {
      newCookieList = [newToken];
    } else {
      newCookieList = [...cookie, newToken];
    }
    const data = [];
    for (var key in newCookieList) {
      if (newCookieList.hasOwnProperty(key)) {
        data.push(newCookieList[key]);
      }
    }
    for (let i = 0; i < data.length; i++) {}

    setCookie("newTokenList", [...data], { path: "/", maxAge: 1000000 });
    cookieAddList();
  };

  const cookieAddList = () => {
    let cookie = document.cookie.indexOf("newTokenList=");
    if (cookie === -1) {
      setDefaultOptions(getBaseTokenLogoURLByTokenSymbol);
    } else {
      let cookieNewList;
      cookieNewList = cookies.newTokenList;
      if (cookieNewList !== undefined) {
        let cookieArray = [...cookieNewList];
        setDefaultOptions([
          ...getBaseTokenLogoURLByTokenSymbol,
          ...cookieArray,
        ]);
      }
    }
  };

  useEffect(() => {
    cookieAddList();
  }, []);

  //------------------------------------------------Searching----------------------------------------------------//

  //----SearchBar Token----//
  const handleSearch = (e) => {
    setSearch(e.target.value);
    if (e.target.value) {
      const searchTextLower = e.target.value.toLowerCase();
      const filteredResults = defaultoption.filter((item) => {
        const combinedString = `${item.name} ${item.value}`.toLowerCase();
        return (
          combinedString.includes(searchTextLower) ||
          combinedString.endsWith(searchTextLower)
        );
      });
      setDefaultOptions(filteredResults);
    } else {
      setDefaultOptions(getBaseTokenLogoURLByTokenSymbol);
    }
  };

  useEffect(() => {
    setSearch("");
  }, [coin1, coin2]);

  useEffect(() => {
    cookieAddList();
  }, []);

  //----------------------------------------------+ Get Slippage +---------------------------------------------------------------//

  const getSlippage = () => {
    if (balance === 0) {
      setSlippage(1.5);
    } else {
      const orgAmt = amountIn / balance;
      const one_percentage = orgAmt / 100;
      let slippage_tolerance = one_percentage * slippage; //slippage_percentage
      slippage_tolerance = parseInt(slippage_tolerance);
    }
  };
  useEffect(() => {
    getSlippage();
  }, [slippage]);
  //------------------------------------------+ Check Pair +---------------------------------------------//

  useEffect(() => {
    try {
      const checkPair = async () => {
        var web3 = new Web3(rpcUrl);
        const Pair_Smart_contract = new web3.eth.Contract(
          pair_abi,
          pair_contract
        );
        //--getting the Pair--//
        const pair = await Pair_Smart_contract.methods
          .getPair(coin1, coin2)
          .call();
        setPairAddress(pair);
      };
      checkPair();
    } catch (err) {
      console.log(err);
      setErrorMsg(err.message);
      handleErrorMsg();
    }
  }, [coin1, coin2]);
  //-------------------------------+ Price Impact +---------------------------//
  const priceImpactCalFun = async () => {
    const web3Modal = new Web3Modal();
    const connection = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(connection);
    const pairContract = new ethers.Contract(
      pairAddress,
      PairTokenAbi,
      provider
    );
    const token0Add = await pairContract.token0();
    const token1Add = await pairContract.token1();
    const token0Contract = new ethers.Contract(token0Add, ERC20ABI, provider);
    const token1Contract = new ethers.Contract(token1Add, ERC20ABI, provider);
    const token0Balance = await token0Contract.balanceOf(pairAddress);
    let token1Balance = await token1Contract.balanceOf(pairAddress);

    token1Balance = token1Balance / Math.pow(10, decimal1);

    // const amtOut=ethers.utils.parseUnits(amountOut,decimal2)
    let priceImpactCal = (amountOut / token1Balance) * 100;
    priceImpactCal = parseFloat(priceImpactCal).toFixed(2);

    if (priceImpactCal >= 99.4) {
      setPriceImpact(99.4);
    } else {
      setPriceImpact(priceImpactCal);
    }
  };

  useEffect(() => {
    priceImpactCalFun();
  }, [coin1, coin2, amountOut, pairAddress]);
  //-----------------------------------------+ New Token List +---------------------------------------------------//
  const [checkedFlair, setCheckedFlair] = useState(true);
  const [checkedSolana, setCheckedSolana] = useState(false);
  const [checkedPermissionPool, setCheckedPermissionPool] = useState(false);

  useEffect(() => {
    if (checkedFlair) {
      if (checkedSolana === false && checkedPermissionPool === false) {
        setDefaultOptions(getBaseTokenLogoURLByTokenSymbol);
      } else if (checkedSolana) {
        setDefaultOptions([
          ...getBaseTokenLogoURLByTokenSymbol,
          ...SolanaTokenList,
        ]);
      } else if (checkedPermissionPool) {
        setDefaultOptions([
          ...getBaseTokenLogoURLByTokenSymbol,
          ...PermissionPoolTokenList,
        ]);
      }
    } else if (checkedSolana) {
      if (checkedFlair === false && checkedPermissionPool === false) {
        setDefaultOptions(SolanaTokenList);
      } else if (checkedFlair) {
        setDefaultOptions([
          ...getBaseTokenLogoURLByTokenSymbol,
          ...SolanaTokenList,
        ]);
      } else if (checkedPermissionPool) {
        setDefaultOptions([...SolanaTokenList, ...PermissionPoolTokenList]);
      }
    } else if (checkedPermissionPool) {
      if (checkedFlair === false && checkedSolana === false) {
        setDefaultOptions(PermissionPoolTokenList);
      } else if (checkedSolana) {
        setDefaultOptions([...SolanaTokenList, ...PermissionPoolTokenList]);
      } else if (checkedFlair) {
        setDefaultOptions([
          ...getBaseTokenLogoURLByTokenSymbol,
          ...PermissionPoolTokenList,
        ]);
      }
    } else if (checkedFlair && checkedSolana && checkedPermissionPool) {
      setDefaultOptions([
        ...getBaseTokenLogoURLByTokenSymbol,
        ...SolanaTokenList,
        ...PermissionPoolTokenList,
      ]);
    } else {
      setDefaultOptions(getBaseTokenLogoURLByTokenSymbol);
    }
  }, [checkedFlair, checkedSolana, checkedPermissionPool]);

  //-------------------------------------+Handling Button Msg +-------------------------------------------//
  const [buttonMsg, setButtonMsg] = useState(`Approve  ${lbl1}`);
  useEffect(() => {
    setButtonMsg(`Approve ${lbl1}`);
  }, [lbl1]);
  const [button, setButton] = useState(false);

  const handleButtonMsg = async () => {
    const web3Modal = new Web3Modal();
    const connection = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(connection);
    const signer = provider.getSigner();

    if (type1 === "token") {
      const coin1Contract = new ethers.Contract(coin1, ERC20ABI, signer);
      let allowance1 = await coin1Contract.allowance(address, PANCAKE_ROUTER);
      allowance1 = ethers.utils.formatUnits(allowance1, decimal1);
      let totalSupply = await coin1Contract.totalSupply();
      totalSupply = ethers.utils.formatUnits(totalSupply, decimal1);

      if (allowance1 <= totalSupply) {
        setButton(true);
        setWalletMsg("Waiting For Approval");
      } else {
        setButton(false);
        setWalletMsg("Proceed");
      }
    } else {
      setButton(false);
      setWalletMsg("Proceed");
    }
  };

  const approval = async () => {
    setButtonMsg(`Approving ${lbl1} Please Wait`);
    const web3Modal = new Web3Modal();
    const connection = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(connection);
    const signer = provider.getSigner();
    const ContractApproval = new ethers.Contract(coin1, ERC20ABI, signer);

    const AmountIn = ethers.utils.parseUnits(
      "11579208923731619542357098500868790785326998466",
      decimal1
    );
    let approve = await ContractApproval.approve(PANCAKE_ROUTER, AmountIn);
    const result = await approve.wait();
    setButton(false);
    setWalletMsg("Proceed");
  };

  const swapping = async () => {
    waitModel();
    setWallet(false);
    const deadline = Date.now() + 1000 * 60 * 5; // 5min
    const address1 = address;
    const to = address1;
    let path = [coin1, coin2];
    try {
      if (amountIn !== "" && amountIn !== undefined && amountIn > 0) {
        // console.log({amountIn});
        if (amountIn > parseFloat(coin1Balance)) {
          setErrorMsg(`You Dont Have Sufficient ${lbl1}  In Your Wallet`);
          handleErrorMsg();
          waitModelHide();
          setButtonMsg("Approve Token");
          setAmountIn("");
        } else {
          const web3Modal = new Web3Modal();
          const connection = await web3Modal.connect();
          const provider = new ethers.providers.Web3Provider(connection);
          //let signer = wallet.connect(Provider);
          const signer = provider.getSigner();
          var web3 = new Web3(provider);
          let contract = new ethers.Contract(
            PANCAKE_ROUTER,
            PANCAKE_ROUTER_abi,
            signer
          );
          const Pair_Smart_contract_CreatePair = new ethers.Contract(
            pair_contract,
            pair_abi,
            signer
          );
          const Pair_Smart_contract = new ethers.Contract(
            pair_contract,
            pair_abi,
            provider
          );
          //getting the Pair
          const pair = await Pair_Smart_contract.getPair(coin1, coin2);
          setPairAddress(pair);
          //getting the balance of pair
          const balance = await provider.getBalance(pair);
          setBalance(balance);

          //----+ checking pair is null +---//s
          if (pair !== null && pair !== undefined) {
            // checking if pair is 0x000000000000000000000000000(doesn't exist)
            if (pair.toString().indexOf("0x0000000000000") > -1) {
              // console.log(`pairAddress ${pair} not detected. Auto restart`);
              //----+ creating new Pair +-----//
              const Createpair =
                await Pair_Smart_contract_CreatePair.createPair(coin1, coin2);
              //setWalletMSg("Insufficient Liquidity funds")
              swapping();
            }
            //if pair Exist
            else {
              const ERC20ContractPair = new web3.eth.Contract(ERC20ABI, pair);
              const coin1Contract = new ethers.Contract(
                coin1,
                ERC20ABI,
                signer
              );
              //checking  tokenIn(if WBNB call swapExactETHForTokens else swapExactTokensForTokens )
              //swapExactETHForTokens
              if (type1 === "coin") {
                let coin1amountIn = ethers.utils.parseUnits(amountIn, decimal1);
                const amt = coin1amountIn.toString();
                let tx = await contract.swapExactETHForTokens(
                  amoutOutmin.toString(),
                  [coin1, coin2],
                  to,
                  deadline,
                  { value: amt }
                );
                const result = await tx.wait();
                console.log(`Tx-hash: ${result.hash}`);
                setTx(tx);
                handleTransaction();
                setAmountIn("");
                setAmountOut("");
                getTokenBalance1();
                getTokenBalance2();
              } else if (type2 === "coin") {
                let coin1amountIn = ethers.utils.parseUnits(amountIn, decimal1);
                //coin1amountIn=parseInt(coin1amountIn)
                const amt = coin1amountIn.toString();
                let tx = await contract.swapExactTokensForETH(
                  amt,
                  amoutOutmin.toString(),
                  [coin1, coin2],
                  to,
                  deadline
                );
                const result = await tx.wait();
                console.log(`Tx-hash: ${result.hash}`);
                setTx(tx);
                handleTransaction();
                setAmountIn("");
                setAmountOut("");
                getTokenBalance1();
                getTokenBalance2();
              }
              //swapExactTokensForTokens
              else {
                let coin1amountIn = ethers.utils.parseUnits(amountIn, decimal1);
                let tx = await contract.swapExactTokensForTokens(
                  coin1amountIn.toString(),
                  amoutOutmin.toString(),
                  path,
                  to,
                  deadline
                );
                const result = await tx.wait();
                console.log(`Tx-hash: ${result.hash}`);
                setTx(tx);
                handleTransaction();
                setAmountIn("");
                setAmountOut("");
                getTokenBalance1();
                getTokenBalance2();
              }
            }
          }
        }
      } else {
        // walletMsg("Enter Amount");
      }
    } catch (err) {
      waitModelHide();
      //toast.info(<div> Try to Change the slippage</div>, { autoClose: 5000, toastId: 'info1' });
      console.log(err);
      setErrorMsg(err.message);
      handleErrorMsg();
    }
  };

  useEffect(() => {
    cookieAddList();
  }, []);

  useEffect(() => {
    if (amountIn === "") {
      setAmountOut("");
    }
  }, [amountIn]);
  useEffect(() => {
    console.log(type1, type2);
  }, [type1, type2]);

  //+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++//
  return (
    <>
      <InnerHeader />
      <ToastContainer />
      <div id="content">
        <section className="">
          <Container>
            <div className="app-text text-center">
              <div className="app-title">
                <span className="common-color common-title">
                  Hyperflair Swap
                </span>
              </div>
              <div className="app-subtitle">
                Swap and Trade tokens instantly
              </div>
            </div>
            <Row className="mt-3">
              <Col md={12} className="text-center">
                <div className="tabing">
                  <div className="tab-style">
                    <div className="tab-one active">
                      <Link to="/swap">Swap</Link>
                    </div>
                    <div className="tab-two">
                      <Link to="/liquidity">Liquidity</Link>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <div className="swap-page common-table">
          {/*Token 1 */}
          <TokenModal
            show={token1}
            hide={handleToken1}
            handleSearchToken={handleSearch}
            value={search}
          >
            {defaultoption
              .filter((i) => i.address != coin2)
              .map((item, index) => (
                <li
                  className="token-info"
                  key={index}
                  onClick={() => {
                    setLbl1(item.label);
                    setLogoImg1(item.logo);
                    setCoin1(item.value ? item.value : item.address);
                    setDecimal1(item.decimals);
                    setType1(item.type);
                    handleToken1();
                  }}
                >
                  <div className="token-img">
                    <img src={item.logo} style={{ borderRadius: "50%" }} />
                  </div>
                  <div className="token-text">
                    <span>{item.name}</span>
                  </div>
                  <span style={{ marginLeft: "6rem" }}>{item.button}</span>
                </li>
              ))}
          </TokenModal>
          {/* Token 2*/}
          <TokenModal
            show={token2}
            hide={handleToken2}
            handleSearchToken={handleSearch}
            value={search}
          >
            {defaultoption
              .filter((i) => i.address != coin1)
              .map((item, index) => (
                <li
                  className="token-info"
                  onClick={() => {
                    setLbl2(item.label);
                    setLogoImg2(item.logo);
                    setCoin2(item.address ? item.address : item.value);
                    setDecimal2(item.decimals);
                    setType2(item.type);
                    handleToken2();
                  }}
                  key={index}
                >
                  <div className="token-img">
                    <img src={item.logo} style={{ borderRadius: "50%" }} />
                  </div>
                  <div className="token-text">
                    <span>{item.name}</span>
                  </div>
                  <span style={{ marginLeft: "6rem" }}>{item.button}</span>
                </li>
              ))}
          </TokenModal>
          <div className="tokenlist-modal-root">
            <Modal
              show={tokenlist}
              onHide={handleTokenList}
              className="tokenlist-modal-root"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Select a token</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="switch-info">
                  <ul>
                    <li>
                      <div className="token-name">
                        <span>Flair Default List</span>
                      </div>
                      <div className="token-switch">
                        <BootstrapSwitchButton
                          onstyle="outline-dark"
                          offstyle="outline-light"
                          checked={checkedFlair}
                          size="xs"
                          width={10}
                          height={10}
                          boolean={false}
                          onChange={() => setCheckedFlair(!checkedFlair)}
                        />
                      </div>
                    </li>
                    <li>
                      <div className="token-name">
                        <span>Binance Token List</span>
                      </div>
                      <div className="token-switch">
                        <BootstrapSwitchButton
                          onstyle="outline-dark"
                          offstyle="outline-light"
                          checked={checkedSolana}
                          size="xs"
                          width={10}
                          height={10}
                          onChange={() => setCheckedSolana(!checkedSolana)}
                        />
                      </div>
                    </li>
                    <li>
                      <div className="token-name">
                        <span>Permissionless Pool Tokens </span>
                      </div>
                      <div className="token-switch">
                        <BootstrapSwitchButton
                          onstyle="outline-dark"
                          offstyle="outline-light"
                          checked={checkedPermissionPool}
                          size="xs"
                          width={10}
                          height={10}
                          onChange={() =>
                            setCheckedPermissionPool(!checkedPermissionPool)
                          }
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </Modal.Body>
            </Modal>
          </div>
          <Container>
            <Row>
              <Col md={12}>
                <div className="swap-box">
                  <div className="swap container">
                    <div className="card">
                      <div className="card-body">
                        <div className="coin-select">
                          <div className="label fs-container">
                            <span>From</span>
                            <span>
                              Balance:{" "}
                              {typeof coin1Balance === "number"
                                ? parseFloat(coin1Balance)?.toFixed(2)
                                : coin1Balance}
                            </span>
                          </div>
                          <div className="coin-input">
                            <div className="main-input fs-container">
                              <button className="select-button fc-container">
                                <div
                                  className="fc-container"
                                  onClick={handletoken1}
                                >
                                  <div className="img-icon">
                                    <img src={logoimg1} />
                                  </div>
                                  <span>{lbl1}</span>
                                </div>
                                <i
                                  aria-label="icon: caret-down"
                                  className="anticon anticon-caret-down"
                                >
                                  <svg
                                    viewBox="0 0 1024 1024"
                                    data-icon="caret-down"
                                    width="1em"
                                    height="1em"
                                    fill="currentColor"
                                    aria-hidden="true"
                                    focusable="false"
                                    className=""
                                  >
                                    <path d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"></path>
                                  </svg>
                                </i>
                              </button>
                              <button className="btn-max" onClick={max1}>
                                Max
                              </button>
                              <input
                                inputMode="decimal"
                                autoComplete="off"
                                autoCorrect="off"
                                placeholder="0.00"
                                type="number"
                                pattern="^[0-9]*[.,]?[0-9]*$"
                                minLength="1"
                                maxLength="79"
                                spellCheck="false"
                                value={amountIn}
                                onChange={handleChnageAmount}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="change-side fc-container">
                          <div className="fc-container">
                            <a onClick={handleExchange}>
                              <i
                                aria-label="icon: swap"
                                className="anticon anticon-swap"
                              >
                                <svg
                                  viewBox="64 64 896 896"
                                  data-icon="swap"
                                  width="1em"
                                  height="1em"
                                  fill="currentColor"
                                  aria-hidden="true"
                                  focusable="false"
                                  //onClick={handleExchange}
                                >
                                  <path d="M847.9 592H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h605.2L612.9 851c-4.1 5.2-.4 13 6.3 13h72.5c4.9 0 9.5-2.2 12.6-6.1l168.8-214.1c16.5-21 1.6-51.8-25.2-51.8zM872 356H266.8l144.3-183c4.1-5.2.4-13-6.3-13h-72.5c-4.9 0-9.5 2.2-12.6 6.1L150.9 380.2c-16.5 21-1.6 51.8 25.1 51.8h696c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z"></path>
                                </svg>
                              </i>
                            </a>
                          </div>
                        </div>
                        <div className="coin-select">
                          <div className="label fs-container">
                            <span>To</span>
                            <span>
                              Balance:{" "}
                              {typeof coin2Balance === "number"
                                ? parseFloat(coin2Balance)?.toFixed(2)
                                : coin2Balance}
                            </span>
                          </div>
                          <div className="coin-input">
                            <div className="main-input fs-container">
                              <button className="select-button fc-container">
                                <div
                                  className="fc-container"
                                  onClick={handletoken2}
                                >
                                  <div className="img-icon">
                                    <img src={logoimg2} />
                                  </div>
                                  <span>{lbl2}</span>
                                </div>
                                <i
                                  aria-label="icon: caret-down"
                                  className="anticon anticon-caret-down"
                                >
                                  <svg
                                    viewBox="0 0 1024 1024"
                                    data-icon="caret-down"
                                    width="1em"
                                    height="1em"
                                    fill="currentColor"
                                    aria-hidden="true"
                                    focusable="false"
                                    className=""
                                  >
                                    <path d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"></path>
                                  </svg>
                                </i>
                              </button>
                              <button className="btn-max" onClick={max2}>
                                Max
                              </button>
                              <input
                                inputMode="decimal"
                                autoComplete="off"
                                autoCorrect="off"
                                placeholder="0.00"
                                type="number"
                                pattern="^[0-9]*[.,]?[0-9]*$"
                                minLength="1"
                                maxLength="79"
                                spellCheck="false"
                                value={amountOut}
                                onChange={handleChnageAmount2}
                              />
                            </div>
                          </div>
                        </div>
                        {(coin1 !== "") & (coin2 !== "") ? (
                          <div className="price-info">
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="font-medium text-xs text-md-light">
                                Price
                              </div>
                              <div>
                                {" "}
                                {pricePerTOken} {lbl2} per {lbl1}
                              </div>
                            </div>

                            {/* <div className="d-flex align-items-center justify-content-between">
                              <div className="font-medium text-xs text-md-light">
                                Swapping Through
                              </div>
                              <div>Hyper-Flair Pool</div>
                            </div> */}
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="font-medium text-xs text-md-light">
                                Minimum Received
                              </div>
                              <div>
                                {amountMinRec} {lbl2}
                              </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="font-medium text-xs text-md-light">
                                Price Impact
                              </div>
                              {priceImpact > 5 ? (
                                <div style={{ color: "red" }}>
                                  {priceImpact}%
                                </div>
                              ) : priceImpact > 3 ? (
                                <div style={{ color: "yellow" }}>
                                  {priceImpact}%
                                </div>
                              ) : priceImpact > 1 ? (
                                <div style={{ color: "black" }}>
                                  {priceImpact}%
                                </div>
                              ) : priceImpact > 0.0 ? (
                                <div style={{ color: "green" }}>
                                  {priceImpact}%
                                </div>
                              ) : (
                                <div style={{ color: "white" }}>
                                  {priceImpact}%
                                </div>
                              )}
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="font-medium text-xs text-md-light">
                                Explorer
                              </div>
                              <div>
                                <a
                                  href={`https://bscscan.com/address/${pairAddress}`}
                                  target="_blank"
                                >
                                  BSC Scan
                                </a>
                              </div>
                            </div>

                            <div className="d-flex align-items-center justify-content-between">
                              <div className="font-medium text-xs">
                                Slippage Tolerance
                              </div>
                              <div className="text-xs coin-select  slippage-input">
                                <input
                                  inputMode="decimal"
                                  autoComplete="off"
                                  autoCorrect="off"
                                  placeholder="0.00"
                                  type="text"
                                  pattern="^[0-9]*[.,]?[0-9]*$"
                                  minLength="1"
                                  maxLength="79"
                                  spellCheck="false"
                                  value={slippage}
                                  onChange={(e) => setSlippage(e.target.value)}
                                  style={{ width: "2.5em" }}
                                />
                              </div>

                              <AiFillSetting
                                onClick={handleShowSlippage}
                                style={{ marginLeft: "-60px" }}
                              />
                              {/* Slippage */}
                              <SliipageModal
                                show={showSlippage}
                                hide={CloseSlippage}
                                setSlippage={setSlippage}
                                value={slippage}
                              />
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="card-button-swap">
                          {address ? (
                            <button
                              type="button"
                              className={
                                walletMsg == "Swap" ||
                                walletMsg == "Connect Wallet" ||
                                walletMsg == "Proceed"
                                  ? "btn-primary"
                                  : "btn-gray"
                              }
                              onClick={
                                walletMsg === "Connect Wallet"
                                  ? Connect
                                  : walletMsg === "Swap"
                                  ? handleButtonMsg
                                  : walletMsg === "Proceed"
                                  ? handleConfirm
                                  : ""
                              }
                            >
                              {walletMsg}
                            </button>
                          ) : (
                            <Connect />
                          )}
                          {button ? (
                            <button
                              type="button"
                              className={
                                buttonMsg === `Approve ${lbl1}`
                                  ? "btn-primary"
                                  : "btn-gray"
                              }
                              onClick={approval}
                            >
                              {buttonMsg}
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div className="overlay"></div>

      <ConfirmModalSwap
        show={confirm}
        hide={handleConfirmHide}
        action={swapping}
        ShowMsg="Confirm Swap"
        Label1={lbl1}
        Label2={lbl2}
        AmountIn={amountIn}
        AmountOut={amountOut}
        amountMinRec={amountMinRec}
      />
      <TransactionModal
        show={transaction}
        hide={handleTransactionHide}
        tx={tx}
      />

      <WaitingModal show={wait} hide={waitModelHide} />

      <ErrorModal
        show={errShow}
        hide={handleErrorMsgHide}
        errorMsg={errorMsg}
      />
    </>
  );
};
export default Swap;
