import { Modal } from 'react-bootstrap'
import React from 'react'
import './style.css'
export default function WaitingModal(props) {
  return (
    <>
      <Modal
        show={props.show}
        onHide={props.hide}
        className="token-modal-root"
        centered
        style={{ bottomBorder: "none" }}
      >
        <Modal.Header className="pb-3" closeButton style={{ borderBottom: "none" }}>
          <Modal.Title className="fs-5"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="d-flex align-items-center justify-content-center">
            <lord-icon src="https://cdn.lordicon.com/kbtmbyzy.json"
              trigger="loop" delay="2000" colors="primary:#ffffff,secondary:#38ef7d"
              style={{ width: "150px", height: "150px" }}>
            </lord-icon>
          </div>
          <div class="text-center" id="pre-confirmation">
            <h3 className="lh-lg">Waiting for confirmation</h3>
            <p className="lh-lg pt-0">Confirm this transaction in your wallet</p>
          </div>
        </Modal.Body>

      </Modal>

    </>
  )
}
