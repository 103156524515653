import React from 'react'
import InnerHeader from '../Inner-Header'
import { Container } from 'react-bootstrap'

const CommingSoon = () => {
  return (
    <>
      <InnerHeader />
      <div id="content" style={{ height: "100%", minHeight: "100%" }}>
        <section>
          <Container>
            <div className='comming-soon-wrapper'>
              <div className='text-center'>
                <img src="/static/media/logo.4abc0221cbf4b58a0166.png" alt="" className='img-fluid' />
                <h2>
                  <span class="gradient-text">Launching Soon</span>
                  <span class="dot">.</span>
                  <span class="dot">.</span>
                  <span class="dot">.</span>
                  <span class="dot">.</span>
                </h2>
              </div>
            </div>
          </Container>
        </section>
      </div>
    </>
  )
}

export default CommingSoon