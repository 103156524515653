import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import {
  ERC20ABI,
  WBNBCOIN,
  tokenLogo,
} from "../assets/Constant/DefaultValues";
import {
  PANCAKE_ROUTER,
  PANCAKE_ROUTER_abi,
} from "../assets/Constant/Contract/RouterContract";
import {
  PairTokenAbi,
  pair_abi,
  pair_contract,
} from "../assets/Constant/Contract/FactoryContract";
import React, {useEffect, useState } from "react";

import InnerHeader from "./Inner-Header";
import { Link } from "react-router-dom";
import TokenModal from "./Modals/TokenModal";
import Web3 from "web3";
import Web3Modal from "web3modal";
import { ethers } from "ethers";
import { getBaseTokenLogoURLByTokenSymbol } from "../assets/Constant/Tokens/Token";
import { useCookies } from "react-cookie";
import { useWeb3React } from "@web3-react/core";
import { useAccount } from "wagmi";

const Liquidity = () => {
  const { connector, library, chainId, account, activate, deactivate, active } =
    useWeb3React();
    const {address}=useAccount()

  //#################################################-----------#########################################################//
  /*token modal*/
  const [token1, setToken1] = useState(false);
  const handleToken1 = () => setToken1(false);
  const handletoken1 = () => setToken1(true);

  /*Token modal 2 */
  const [token2, setToken2] = useState(false);
  const handleToken2 = () => setToken2(false);
  const handletoken2 = () => setToken2(true);

  //Search
  const [searchToken, setSearchToken] = useState("");
  /*search modal*/
  const [search, setSearch] = useState(false);

  //decimal token 1
  const [decimal1, setDecimal1] = useState(18);

  //decimal token2
  const [decimal2, setDecimal2] = useState(18);

  //Token List
  const [defaultoption, setDefaultOptions] = useState(
    getBaseTokenLogoURLByTokenSymbol
  );
  //token type
  const [type1, setType1] = useState("coin");
  const [type2, setType2] = useState("token");

  //Token For Pairing
  const [coin1, setCoin1] = useState(getBaseTokenLogoURLByTokenSymbol[0].value);
  const [coin2, setCoin2] = useState(getBaseTokenLogoURLByTokenSymbol[1].value);

  //Token Label
  const [lbl1, setLbl1] = useState(getBaseTokenLogoURLByTokenSymbol[0].label);
  const [lbl2, setLbl2] = useState("select Token");
  //Token Logo
  const [logoimg1, setLogoImg1] = useState(
    getBaseTokenLogoURLByTokenSymbol[0].logo
  );
  const [logoimg2, setLogoImg2] = useState(tokenLogo);


  /*Pair Address*/
  const [pairAddress, setPairAddress] = useState(
    "0x62f02096C6AC80552FdE1947eEEc314FfD225713"
  );

  /*Pool Token Value*/
  const [token1Pool, setToken1Pool] = useState("");
  const [token2Pool, setToken2Pool] = useState("");

  /*Lp Token Supply*/
  const [lpTokenSupply, setLpTokenSupply] = useState("");

  /*USer Lp Token Balance*/
  const [lpTokenBalance, setLpToken] = useState(0);

  /*Pool share*/
  const [poolShare, setPoolShare] = useState(0);

  /*LP user*/
  const [lpUser, setLpUser] = useState(false);

  /*NewToken*/
  const [newToken, setNEwToken] = useState([]);
  //Cookie
  const [cookies, setCookie] = useCookies(["newTokenList"]);
  //---------------------------------------------+ Adding New Token +--------------------------------------------------------------//
  //---Add New Token--//
  useEffect(() => {
    const addToken = async () => {
      if (defaultoption.length === 0) {
        const web3Modal = new Web3Modal({
          theme: {
            backgroundColor: "rgba(0, 0, 0,0)",
          },
        });
        const connection = await web3Modal.connect();
        const provider = new ethers.providers.Web3Provider(connection);
        const web3 = new Web3(provider);
        const new_token = web3.utils.toChecksumAddress(searchToken);
        const Contract_new_token = new ethers.Contract(
          new_token,
          ERC20ABI,
          provider
        );

        const decimal = await Contract_new_token.decimals();
        const Label = await Contract_new_token.symbol();
        const name = await Contract_new_token.name();
        const newTokenAdd = {
          value: new_token,
          name: name,
          label: Label,
          decimals: decimal,
          logo: tokenLogo,
          type: "token",
        };
        setNEwToken(newTokenAdd);
        let newTokenArr = [];
        newTokenArr.push({
          value: new_token,
          name: name,
          label: Label,
          decimals: decimal,
          logo: tokenLogo,
          type: "token",
          button: (
            <input
              type="button"
              value="import"
              style={{ backgraoundColor: "red" }}
              onClick={cookieAdd}
            />
          ),
        });
        setDefaultOptions(newTokenArr);
        const json_arr = JSON.stringify(newToken);
      }
    };
    addToken();
  });
  //Cookie Add
  const cookieAdd = () => {
    let newCookieList = [];
    let cookieLen = document.cookie.indexOf("newTokenList=");
    let cookie = cookies.newTokenList;
    if (cookieLen === -1) {
      newCookieList.push(newToken);
    } else if (cookie == null) {
      newCookieList = [newToken];
    } else {
      newCookieList = [...cookie, newToken];
    }
    const data = [];
    for (var key in newCookieList) {
      if (newCookieList.hasOwnProperty(key)) {
        data.push(newCookieList[key]);
      }
    }

    //setCookie('newTokenList', newTokenAdd, { path: '/', maxAge: 1000000 });
    setCookie("newTokenList", [...data], { path: "/", maxAge: 1000000 });

    //window.location.reload(false);
    cookieAddList();
  };

  const cookieAddList = () => {
    setDefaultOptions(getBaseTokenLogoURLByTokenSymbol);

    let cookie = document.cookie.indexOf("newTokenList=");
    if (cookie === -1) {
      setDefaultOptions(getBaseTokenLogoURLByTokenSymbol);
    } else {
      let cookieNewList;
      cookieNewList = cookies.newTokenList;

      if (cookieNewList !== undefined) {
        let cookieArray = [...cookieNewList];
        setDefaultOptions([
          ...getBaseTokenLogoURLByTokenSymbol,
          ...cookieArray,
        ]);
      }
    }
  };

  useEffect(() => {
    setDefaultOptions(getBaseTokenLogoURLByTokenSymbol);

    cookieAddList();
  }, []);

  //------------------------------------------------+ Searching +----------------------------------------------------//


  //----SearchBar Token----//
  const handleSearchToken = (e) => {
  const searchTextLower = e.target.value.toLowerCase();
  setSearch(searchTextLower); // Update the search state with the current value
  if (e.target.value) {
    const searchTextLower = e.target.value.toLowerCase();
    const filteredResults = defaultoption.filter((item) => {
      const combinedString = `${item.name} ${item.value}`.toLowerCase();
      return combinedString.startsWith(searchTextLower);
    });
    setDefaultOptions(filteredResults);
  } else {
    setDefaultOptions(getBaseTokenLogoURLByTokenSymbol);
  }
};

  useEffect(() => {
    const searchTokenList = () => {
      if (searchToken !== "") {
        handleSearchToken()
      } else {
        setDefaultOptions(getBaseTokenLogoURLByTokenSymbol);
        cookieAddList();
      }
    };
    searchTokenList();
  }, [searchToken]);
  //------------------------------------------+ Check Pair +---------------------------------------------//

  useEffect(() => {
    try {
      const checkPair = async () => {
        if (type1 == "coin") {
          setCoin1(WBNBCOIN);
        } else if (type2 == "coin") {
          setCoin2(WBNBCOIN);
        }
        const web3Modal = new Web3Modal({
          theme: {
            backgroundColor: "rgba(0, 0, 0,0)",
          },
        });
        const connection = await web3Modal.connect();
        const provider = new ethers.providers.Web3Provider(connection);
        const Pair_Smart_contract = new ethers.Contract(
          pair_contract,
          pair_abi,
          provider
        );
        //---getting the Pair---//
        const pair = await Pair_Smart_contract.getPair(coin1, coin2);
        setPairAddress(pair);
        setPairAddress(pair);
        const coin1Contract = new ethers.Contract(coin1, ERC20ABI, provider);
        let pool1Blanace = await coin1Contract.balanceOf(pair);
        pool1Blanace = ethers.utils.formatUnits(pool1Blanace, decimal1);

        pool1Blanace = parseFloat(pool1Blanace).toFixed(3);
        setToken1Pool(pool1Blanace);
        const coin2Contract = new ethers.Contract(coin2, ERC20ABI, provider);

        let pool2Blanace = await coin2Contract.balanceOf(pair);
        pool2Blanace = ethers.utils.formatUnits(pool2Blanace, decimal2);
        pool2Blanace = parseFloat(pool2Blanace).toFixed(3);
        setToken2Pool(pool2Blanace);
        const pair_Token_Contract = new ethers.Contract(
          pair,
          PairTokenAbi,
          provider
        );
        let UserLPBalance = await pair_Token_Contract.balanceOf(address);

        UserLPBalance = ethers.utils.formatUnits(UserLPBalance, 18);
        let User_LPBalance = parseFloat(UserLPBalance).toFixed(2);
        setLpToken(User_LPBalance);

        let totalSupply = await pair_Token_Contract.totalSupply();
        totalSupply = ethers.utils.formatUnits(totalSupply, 18);

        let pool_share = (UserLPBalance / totalSupply) * 100;
        pool_share = parseFloat(pool_share).toFixed(2);
        setPoolShare(pool_share);
        if (pair.toString().indexOf("0x0000000000000") > -1) {
          setLpTokenSupply(0);
        } else {
          
        }
        if (UserLPBalance > 0) {
          setLpUser(true);
        }
      };
      checkPair();
    } catch (err) {
      console.log(err);
      //   setErrorMsg(err.data.message)
      //   handleErrorMsg();
    }
  }, [coin1, coin2, pairAddress, address, lbl1, lbl2, lpTokenBalance]);
  const value = {
    Label1: lbl1,
    Label2: lbl2,
    Logo1: logoimg1,
    Logo2: logoimg2,
    Dec1: decimal1,
    Dec2: decimal2,
    COIN1: coin1,
    COIN2: coin2,
    lpUser: lpUser,
  };
 
  //+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++//

  return (
    <>
      <InnerHeader />
      <div id="content">
        <section className="mb-5">
          <Container>
            <div className="app-text text-center">
              <div className="app-title">
                <span className="common-color common-title">
                  Hyperflair Liquidity
                </span>
              </div>
              <div className="app-subtitle">
                Add Liquidity to participate in Pools and Farm rewards
              </div>
            </div>
            <Row className="mt-3">
              <Col md={12} className="text-center">
                <div className="tabing">
                  <div className="tab-style">
                    <div className="tab-one">
                      <Link to="/swap">Swap</Link>
                    </div>
                    <div className="tab-two active">
                      <Link to="/liquidity">Liquidity</Link>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <div className="swap-page common-table">
          <Container>
            <Row className="gy-5">
              <Col md={12}>
                <div className="swap-box">
                  <div className="swap container">
                    <div className="card">
                      <div className="card-body">
                        <p className="fw-bold" style={{ fontSize: "14px" }}>
                          If you staked your LP tokens in a farm, unstake them
                          to see them here.
                        </p>
                        <div className="coin-select">
                          <div className="coin-input">
                            <div className="main-input fs-container">
                              <button className="select-button fc-container">
                                <div
                                  className="fc-container"
                                  onClick={handletoken1}
                                >
                                  <div className="img-icon">
                                    <img src={logoimg1} />
                                  </div>
                                  <span
                                    style={{
                                      padding: "2px",
                                      marginTop: "2px",
                                    }}
                                  >
                                    {lbl1}
                                  </span>
                                </div>
                                <i
                                  aria-label="icon: caret-down"
                                  className="anticon anticon-caret-down"
                                >
                                  <svg
                                    viewBox="0 0 1024 1024"
                                    data-icon="caret-down"
                                    width="1em"
                                    height="1em"
                                    fill="currentColor"
                                    aria-hidden="true"
                                    focusable="false"
                                    className=""
                                  >
                                    <path d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"></path>
                                  </svg>
                                </i>
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="change-side fc-container">
                          <div className="fc-container">
                            <i
                              aria-label="icon: plus"
                              className="anticon anticon-plus"
                              Link
                              to="/addliquidity"
                            >
                              <svg
                                viewBox="64 64 896 896"
                                data-icon="plus"
                                width="1em"
                                height="1em"
                                fill="currentColor"
                                aria-hidden="true"
                                focusable="false"
                                className=""
                              >
                                <path d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8z"></path>
                                <path d="M176 474h672q8 0 8 8v60q0 8-8 8H176q-8 0-8-8v-60q0-8 8-8z"></path>
                              </svg>
                            </i>
                          </div>
                        </div>

                        <div className="coin-select">
                          <div className="coin-input">
                            <div className="main-input fs-container">
                              <button className="select-button fc-container">
                                <div
                                  className="fc-container"
                                  onClick={handletoken2}
                                >
                                  <div className="img-icon">
                                    <img src={logoimg2} />
                                  </div>
                                  <span
                                    style={{
                                      padding: "2px",
                                      marginTop: "2px",
                                    }}
                                  >
                                    {lbl2}
                                  </span>
                                </div>
                                <i
                                  aria-label="icon: caret-down"
                                  className="anticon anticon-caret-down"
                                >
                                  <svg
                                    viewBox="0 0 1024 1024"
                                    data-icon="caret-down"
                                    width="1em"
                                    height="1em"
                                    fill="currentColor"
                                    aria-hidden="true"
                                    focusable="false"
                                    className=""
                                  >
                                    <path d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"></path>
                                  </svg>
                                </i>
                              </button>
                            </div>
                          </div>
                        </div>

                        {lpTokenBalance > 0 ? (
                          <Container
                            className="mt-3 lpDetails"
                            style={{ fontSize: "8px" }}
                          >
                            <Col md={12}>
                              <div className="card">
                                <div
                                  className="card-body"
                                  style={{ margin: "-10px" }}
                                >
                                  <span className="mt-0">
                                    <Row>
                                      <Col md={12}>
                                        <img
                                          src={logoimg1}
                                          alt="img1"
                                          style={{
                                            width: "20px",
                                            height: "20px",
                                            borderRadius: "50%",
                                          }}
                                        ></img>
                                        <img
                                          src={logoimg2}
                                          alt="img1"
                                          style={{
                                            width: "20px",
                                            height: "20px",
                                            borderRadius: "50%",
                                          }}
                                          className="me-2"
                                        ></img>{" "}
                                        {lbl1}/{lbl2}
                                      </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                      <Col md={8}>Pooled {lbl1} :</Col>
                                      <Col md={4}>{token1Pool}</Col>
                                    </Row>
                                    <Row>
                                      <Col md={8}>Pooled {lbl2} :</Col>
                                      <Col md={4}>{token2Pool}</Col>
                                    </Row>
                                    <Row>
                                      <Col md={8}>Your pool tokens :</Col>
                                      <Col md={4}>{lpTokenBalance}</Col>
                                    </Row>
                                    <Row>
                                      <Col md={8}>Your pool share :</Col>
                                      <Col md={4}>{poolShare} %</Col>
                                    </Row>
                                    <Row>
                                      <Col md={12} sm={6}>
                                        <div className="text-center mt-3 liquidity-btn-call-section">
                                          <Link
                                            to="/addliquidity"
                                            className="btn-primary liquidity-btn-call"
                                            state={{
                                              Label1: lbl1,
                                              Label2: lbl2,
                                              Logo1: logoimg1,
                                              Logo2: logoimg2,
                                              Dec1: decimal1,
                                              Dec2: decimal2,
                                              COIN1: coin1,
                                              COIN2: coin2,
                                              TYPE1: type1,
                                              TYPE2: type2,
                                            }}
                                          >
                                            Add
                                          </Link>
                                        </div>
                                      </Col>
                                    </Row>
                                  </span>
                                </div>
                              </div>
                            </Col>
                          </Container>
                        ) : (
                          <div className="text-center mt-3 liquidity-btn-call-section">
                            <Link
                              to="/addliquidity"
                              className="btn-primary liquidity-btn-call"
                            >
                              Import
                            </Link>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={12}>
                <div className="swap-box">
                  <div className="swap container">
                    <div className="mb-3">
                      <h5>Create Pool</h5>
                    </div>
                    <div className="card">
                      <div className="card-body">
                        <span className="fw-bold" style={{ fontSize: "14px" }}>
                          Create a liquidity pool on Hyperflair that can be
                          traded on the swap interface. Read the guide before
                          attempting.
                        </span>
                        <Link to="/addliquidity" className="btn-primary">
                          Create Pool
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="collect-modal-root">
          <TokenModal
            show={token1}
            hide={handleToken1}
            handleSearchToken={handleSearchToken}
          >
            {defaultoption.map((item, index) => (
              <li
                className="token-info"
                onClick={() => {
                  setLbl1(item.label);
                  setLogoImg1(item.logo);
                  setCoin1(item.value?item.value:item.address);
                  setDecimal1(item.decimals);
                  setType1(item.type);
                  handleToken1();
                }}
                key={index}
              >
                <div className="token-img">
                  <img src={item.logo} style={{ borderRadius: "50%" }} />
                </div>
                <div className="token-text">
                  <span>{item.name}</span>
                </div>
                <sapn style={{ marginLeft: "6rem" }}>{item.button}</sapn>
              </li>
            ))}
          </TokenModal>
          
          <TokenModal
            show={token2}
            hide={handleToken2}
            handleSearchToken={handleSearchToken}
          >
            {defaultoption.map((item, index) => (
              <li
                className="token-info"
                onClick={() => {
                  setLbl2(item.label);
                  setLogoImg2(item.logo);
                  setCoin2(item.value?item.value:item.address);
                  setDecimal2(item.decimals);
                  setType2(item.type);
                  handleToken2();
                }}
                key={index}
              >
                <div className="token-img">
                  <img src={item.logo} style={{ borderRadius: "50%" }} />
                </div>
                <div className="token-text">
                  <span>{item.name}</span>
                </div>
                <span style={{ marginLeft: "6rem" }}>{item.button}</span>
              </li>
            ))}
          </TokenModal>
        </div>
      </div>
    </>
  );
};
export default Liquidity;
