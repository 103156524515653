import React from "react";
import { Col, Container, Row ,Form,Button} from "react-bootstrap";
import Footer from "./Footer";
import Header from "./Header";

const ContactUs = () =>
{
    return(
        <>
        <Header />
            <div className="contact-us">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={10}>
                            <h6>Support</h6>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col md={10} sm={12}>
                            <div className="contact-form">
                            <Form>
                                <Row>
                                    <Col md={6}>
                                        <Form.Group className="mb-3" controlId="formBasicName">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control type="text" placeholder="Name" aria-label="Name"  required/>
                                        </Form.Group>   
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Email address</Form.Label>
                                        <Form.Control type="email" placeholder="Email Address"  required />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <Form.Group className="mb-3" controlId="formBasicSubject">
                                            <Form.Label>Subject</Form.Label>
                                            <Form.Control type="text"  placeholder="Subject"  required/>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                            <Form.Label>Message</Form.Label>
                                            <Form.Control as="textarea" rows={3} placeholder="Message"  />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Button variant="primary" type="submit">
                                    Submit
                                </Button>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer />
        </>
    )
}

export default ContactUs;