import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import InnerHeader from "./Inner-Header";
import StakingCardPrimary from "./Cards/StakingCardPrimary.js";
import Web3 from "web3";
import Web3Modal from "web3modal";
import { ethers } from "ethers";
import { ERC20ABI, rpcUrl } from '../assets/Constant/DefaultValues'
import { stakeTokenList } from '../assets/stakeToken.js'
import { useAccount } from "wagmi";
import { stakeToken, stakeToken_abi, token_stake_abi, token_stake_address } from "../assets/Constant/Contract/StakeContract.js";
const Staking = () => {
  const { address } = useAccount();

  const BUSDdecimal = 9;
  const web3 = new Web3(rpcUrl);
  let currentReward1
  let currentReward2
  let rewardDiff
  let reward1Day
  let reward365Day
  let AprVal
  /*Amount Entered by user in Flair */
  const [amt, setAmt] = useState();

  /*Provider*/
  const [provider, setProvider] = useState();
  /*Signer*/
  const [signer, setSigner] = useState();
  /*Contract*/
  const [contract, setContract] = useState();
  /*Rewards*/
  const [rewards, setRewards] = useState(0);
  /*approve*/
  const [approved, setApproved] = useState(false);

  const [totalStatkedToken, setTotalStatkedTokens] = useState([]);

  const [userTokenStaked, setUserTokenStaked] = useState(0);

  /*apr*/
  const [apr, setApr] = useState('')
  /*Stake token */
  const [tokenStake, setTokenStake] = useState()

  const [buttonMsg, setButtonMsg] = useState("Confirm")

  /*Modal Token */
  const [token, setToken] = useState(false);
  const handlehide = () => { setToken(false) };
  const handleshow = (tokenadd) => { setToken(true); setTokenStake(tokenadd); checkAllowance() };


  //--unstake amount--//
  const [unstakeAmount, setUnstakeAmount] = useState('')

  /*Unstake Modal Token */
  const [Unstaketoken, setUnstakeToken] = useState(false);
  const handlehideUnstake = () => setUnstakeToken(false);
  const handleshowUnstake = () => setUnstakeToken(true);
  const handleUnstakeAmount = (e) => {
    const val = e.target.value
    setUnstakeAmount(val)
  }

  //--Wait Model--//
  const [wait, setWait] = useState(false)
  const waitModel = () => { setWait(true); handleConfirmHide() }
  const waitModelHide = () => setWait(false)

  const [errorMsg, setErrorMsg] = useState("")
  //--Error Msg Model--//
  const [errShow, setErrShow] = useState(false)
  const handleErrorMsg = () => { setErrShow(true) }
  const handleErrorMsgHide = () => { setWait(false); setErrShow(false); }

  //--Transaction Hash--//
  const [tx, setTx] = useState('');

  //--Confirm Model--//
  const [transaction, setTransaction] = useState(false)
  const handleTransaction = () => { setTransaction(true); setWait(false); }
  const handleTransactionHide = () => setTransaction(false)
  //Confirm Model
  const [confirm, setConfirm] = useState(false)
  const handleConfirmHide = () => setConfirm(false)

  //Handle Confirm
  const handleConfirmTransaction = () => {
    handleConfirmHide();
    setUnstakeToken("")

    if (token) {
      stake()
    }
    else if (Unstaketoken) {
      unstake()
    }
  }


  //----------------------------------------------------------------------------------------------------------//

  const handleButtonMsg = () => {
    if (buttonMsg === "Approve Token") {
      stake()
    }
    else if (buttonMsg === "Confirm") {
      stakeTransaction()
    }
  }

  //---Contract Intialization----//
  const init = async () => {
    const web3Modal = new Web3Modal();
    const connection = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(connection); //provder
    setProvider(provider);
    const signer = provider.getSigner(); //signer
    setSigner(signer);
    const contract = new ethers.Contract(token_stake_address, token_stake_abi, signer);
    setContract(contract); //Contract

  };

  //---handle Amount Change---//
  const handleChange = (event) => {
    setAmt(event.target.value);
  };

  //---calculating rewards---//
  const calRewards = async () => {
    if (address !== undefined) {
      const stakeContract = new ethers.Contract(token_stake_address, token_stake_abi, signer);
      await stakeContract.claimReward()
      //await stakeContract.withdrawCoins();
    } else {
      setErrorMsg("Please Connect To the Wallet")
      handleConfirmHide();
      handlehide();
      handleErrorMsg();
    }
  };


  //--Staking--//
  const stake = async () => {
    if (walletConnected === false) {
      setErrorMsg("Please Connect To the Wallet")
      handleConfirmHide();
      handlehide();
      handleErrorMsg();
    }
    else {
      setButtonMsg(`Approving Stake Token`)
      try {
        const contract_approve = new ethers.Contract(stakeToken, ERC20ABI, signer);

        //----+ Checking Allowance of tokens +---//
        let balance = await contract_approve.balanceOf(address)
        balance = parseInt(balance)
        const amount = ethers.utils.parseUnits(amt, 9)
        if (balance < amount) {
          setErrorMsg(`You Dont Have Sufficient ${tokenStake}  In Your Wallet`)
          handleErrorMsg();
          waitModelHide();
          setButtonMsg("Approve Token")
          setAmt("")
        }
        else {
          let amountInStake = '11579208923731619542357098500868790785326998466';
          const approve = await contract_approve.approve(token_stake_address, amountInStake);
          setApproved(true)
          const waitApprove = await approve.wait()
          setButtonMsg("Confirm")
        }

      } catch (err) {
        setErrorMsg(err.message)
        handleErrorMsg();
        waitModelHide();
        setButtonMsg("Approve Token")
        console.log(err)
      }
    }
  };
  const checkAllowance = async () => {
    const BUSDSmartContract = new web3.eth.Contract(
      ERC20ABI,
      stakeToken,
    )
    let allowance1 = await BUSDSmartContract.methods.allowance(address, token_stake_address).call()
    let totalSupply1 = await BUSDSmartContract.methods.totalSupply().call()
    if (allowance1 > totalSupply1) {
      setButtonMsg("Confirm")
      setApproved(true)
    }
    else {
      setButtonMsg("Approve Token")

    }
  }
  const stakeTransaction = async () => {
    try {
      if (approved === true) {
        const Contract = new ethers.Contract(token_stake_address, token_stake_abi, signer);
        const amountIn = ethers.utils.parseUnits(amt, 9);
        const tx = await Contract.stake(amountIn);
        const result = await tx.wait();
        setTx(tx)
        waitModelHide();
        handleTransaction();
        handlehide();
        setAmt("")
        setButtonMsg("Approve Token")
      }
    }
    catch (err) {
      setErrorMsg(err.message)
      handleErrorMsg();
      waitModelHide();
      console.log(err)
    }
  }

  //----function call for contract intialization---//
  useEffect(() => {
    init();
  }, []);
  useEffect(() => {
    setInterval(() => {
      UserStaked();
    }, 10000)
  });

  const unstake = async () => {
    handlehideUnstake();
    if (!walletConnected) {
      setErrorMsg("Please Connect To the Wallet");
      handleConfirmHide();
      handlehide();
      handleErrorMsg();
      return;
    }

    try {
      const stakeContract = new ethers.Contract(token_stake_address, token_stake_abi, signer);
      const unstakeAmt = ethers.utils.parseUnits(unstakeAmount, 9);
      const tx = await stakeContract.unstake(unstakeAmt);
      const result = await tx.wait();
      setTx(tx);
      waitModelHide();
      handleTransaction();
    } catch (err) {
      setErrorMsg(err.message);
      handleErrorMsg();
      waitModelHide();
      console.log(err);
    }
  };


  //---useBalance---//
  useEffect(() => {
    const StakeTokeBalance = async () => {
      let Balance = 0
      const stakeTokeContract = new web3.eth.Contract(
        stakeToken_abi,
        stakeToken,
      )
      const Decimal = await stakeTokeContract.methods.decimals().call()
      Balance = await stakeTokeContract.methods.balanceOf(address).call()
      Balance = ethers.utils.formatUnits(Balance, Decimal)
      Balance = parseFloat(Balance).toFixed(2)
      setLpBalance(Balance)
    }
    StakeTokeBalance()
  }, [address])
  //---userStak token---//
  const [lpBalance, setLpBalance] = useState([])
  const UserStaked = async () => {
    let userTokenStaked;
    let userRewards;
    let totalStatkedToken;

    try {
      if (address) {
        const stakeContract = new web3.eth.Contract(
          token_stake_abi,
          token_stake_address,
        )
        // stake Contract
        userTokenStaked = await stakeContract.methods.stakeBalanceOf(address).call();
        userTokenStaked = ethers.utils.formatUnits(userTokenStaked, BUSDdecimal)
        setUserTokenStaked(userTokenStaked);
        userRewards = await stakeContract.methods.earned(address).call()//userRewards
        userRewards = ethers.utils.formatUnits(userRewards, BUSDdecimal)
        userRewards = parseFloat(userRewards)
        setRewards(userRewards)
        totalStatkedToken = await stakeContract.methods.totalStaked().call()
        totalStatkedToken = ethers.utils.formatUnits(totalStatkedToken, BUSDdecimal)
        setTotalStatkedTokens(totalStatkedToken);
      } 
      // else {
      //   setErrorMsg("Please Connect To the Wallet First")
      //   handleConfirmHide();
      //   handlehide();
      //   handleErrorMsg();
      // }
    } catch (err) {
      // console.log(err);
    }
  };
  const [walletConnected, setWallectConnected] = useState(true)
  const WalletConnect = (data) => {
    setWallectConnected(data)
  }
  //-----------------------------------------------+ APR +------------------------------------------------------//

  const APR = async () => {

    if (address != undefined || address != null) {
      const web3Modal = new Web3Modal();
      const connection = await web3Modal.connect();
      const provider = new ethers.providers.Web3Provider(connection);
      const stakeContract = new web3.eth.Contract(
        token_stake_abi,
        token_stake_address,
      )
      currentReward1 = await stakeContract.methods.earned(address).call()
    }
  }
  APR()
  useEffect(() => {
    if (address != undefined || address != null) {
      setTimeout(async () => {
        const web3Modal = new Web3Modal();
        const connection = await web3Modal.connect();
        const provider = new ethers.providers.Web3Provider(connection);
        const stakeContract = new web3.eth.Contract(
          token_stake_abi,
          token_stake_address,
        )
        currentReward2 = await stakeContract.methods.earned(address).call()
        rewardDiff = currentReward2 - currentReward1
        console.log(rewardDiff)
        reward1Day = rewardDiff * 28800
        reward365Day = reward1Day * 365
        reward365Day = ethers.utils.formatUnits(reward365Day, 9)
        console.log("reward365Day", reward365Day)
        AprVal = (4162 / 100) * reward365Day
        AprVal = AprVal.toFixed(3)
        console.log("APR", AprVal)
        setApr(AprVal)
      }, 3000)
    }
    return function cleanup() {
      clearInterval();
    };
  }, [address])

  return (
    <>
      <InnerHeader />
      <div id="content">
        <section className="">
          <Container>
            <div className="app-text text-center mb-3">
              <div className="app-title">
                <span className="common-color common-title">
                  Hyperflair <span className="heading_match">Stake(BSC)</span>
                </span>
              </div>
              <div className="app-subtitle">
                Lock your Hyperflair Tokens in our staking program to effortlessly
                earn more Hyperflair as rewards.
              </div>
            </div>
            <div className="row justify-content-center common-table g-4">
              <div className="col-12 col-md-6 col-lg-4">
                <StakingCardPrimary lpBalance={lpBalance} />
              </div>
              {/* <div className="col-12 col-md-6 col-lg-4">
                <StakingCardThird
                  Apr={emeraldBalance}
                  lpBalance={lpBalance}
                />
              </div> */}
            </div>
          </Container>
        </section>
      </div>
    </>
  );
};

export default Staking;
