import "react-toastify/dist/ReactToastify.css";

import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import {
  ERC20ABI,
  WBNBCOIN,
  tokenLogo,
} from "../assets/Constant/DefaultValues";
import {
  PANCAKE_ROUTER,
  PANCAKE_ROUTER_abi,
} from "../assets/Constant/Contract/RouterContract";
import {
  pair_abi,
  pair_contract,
} from "../assets/Constant/Contract/FactoryContract";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Wallet, {
  Connect,
  ProceedContext,
} from "../components/Wallet/WalletConnect";

import { AiFillSetting } from "react-icons/ai";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import ConfirmModal from "./Modals/ConfirmModal";
import ErrorModal from "./Modals/ErrorModal";
import InnerHeader from "./Inner-Header";
import { Link } from "react-router-dom";
import { PermissionPoolTokenList } from "../assets/Constant/Tokens/PermissionPoolTokens";
import SliipageModal from "./Modals/SliipageModal";
import { SolanaTokenList } from "../assets/Constant/Tokens/SolanaTokens";
import TokenModal from "./Modals/TokenModal";
import TransactionModal from "./Modals/TransactionModal";
import WaitingModal from "./Modals/WaitingModal";
import Web3 from "web3";
import Web3Modal from "web3modal";
import { ethers } from "ethers";
import { getBaseTokenLogoURLByTokenSymbol } from "../assets/Constant/Tokens/Token";
import { rpcUrl } from "../assets/Constant/DefaultValues";
import { useCookies } from "react-cookie";
import { useLocation } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import { useAccount } from "wagmi";

//++++++++++++++++++++++++++++++++++++++++++--------0000-------++++++++++++++++++++++++++++++++++++++++++++++++++//

const Liquidity = () => {
  const {address}= useAccount()
  /*info modal*/
  const [progress, setProgress] = useState(false);
  const handleProgress = () => setProgress(false);
  const handleprogress = () => setProgress(true);

  /*info modal*/
  const [info, setInfo] = useState(false);
  const handleInfo = () => setInfo(false);
  const handleinfo = () => setInfo(true);

  /*setting modal*/
  const [setting, setSetting] = useState(false);
  const handleSetting = () => setSetting(false);
  const handlesetting = () => setSetting(true);

  /*search modal*/
  const [search, setSearch] = useState(false);
  const handleSearch = () => setSearch(false);
  const handlesearch = () => setSearch(true);

  /*token list modal*/
  const [tokenlist, setTokenList] = useState(false);
  const handleTokenList = () => setTokenList(false);
  const handletokenlist = () => setTokenList(true);
  /*token modal*/
  const [token1, setToken1] = useState(false);
  const handleToken1 = () => {
    setToken1(false);
    cookieAddList();
  };
  //const handleToken1 = () => {setToken1(false)};
  const handletoken1 = () => setToken1(true);

  /*Token modal 2 */
  const [token2, setToken2] = useState(false);
  const handleToken2 = () => {
    setToken2(false);
    cookieAddList();
  };
  const handletoken2 = () => setToken2(true);

  //Search
  const [searchToken, setSearchToken] = useState("");
  //Cookie
  const [cookies, setCookie] = useCookies(["newTokenList"]);

  //token type
  const [type1, setType1] = useState("coin");
  const [type2, setType2] = useState("token");

  //Token For Pairing
  const [coin1, setCoin1] = useState(WBNBCOIN);
  const [coin2, setCoin2] = useState("");
  const [coin, setCoin] = useState("");

  //Token Label
  const [lbl1, setLbl1] = useState(getBaseTokenLogoURLByTokenSymbol[0].label);
  const [lbl2, setLbl2] = useState("Select a currency");

  //Token Logo
  const [logoimg1, setLogoImg1] = useState(
    getBaseTokenLogoURLByTokenSymbol[0].logo
  );
  const [logoimg2, setLogoImg2] = useState(tokenLogo);

  //Amount In
  const [amountIn, setAmountIn] = useState("");

  //Amount Out
  const [amountOut, setAmountOut] = useState("");

  //Factory Contract
  const [factoryContract, setFactoryContract] = useState(null);

  //Router Contract
  const [routerContract, setRouterContract] = useState(null);

  //decimal token 1
  const [decimal1, setDecimal1] = useState(18);

  //decimal token2
  const [decimal2, setDecimal2] = useState(18);

  //Token List
  const [defaultoption, setDefaultOptions] = useState([]);

  //Wallet Connect
  const [wallet, setWallet] = useState(false);

  //Wallet msg
  const [walletMsg, setWalletMsg] = useState("Connect Wallet");
  const [show, setShow] = useState(true);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(!show);

  const [walletConnect, setWallectConnect] = useState(false);

  //Balance Of Tokens
  const [coin1Balance, setCoin1Balance] = useState(0);
  const [coin2Balance, setCoin2Balance] = useState(0);

  //Slippage
  const [slippage, setSlippage] = useState(1.5);
  //To Calculate Slippage
  const [showSlippage, setShowSlippage] = useState(false);
  const handleShowSlippage = () => setShowSlippage(true);
  const CloseSlippage = () => setShowSlippage(false);

  //pairContract Balance
  const [balance, setBalance] = useState();

  //amountOutMin
  const [amoutOutmin, setAmoutOutMin] = useState();

  //amoutout
  const [amountTokenVal, setAmountTokenVal] = useState("");
  //newToken
  const [newToken, setNEwToken] = useState([]);

  //Pair Address
  const [pairAddress, setPairAddress] = useState(null);

  //Pool Token Value
  const [token1Pool, setToken1Pool] = useState("");
  const [token2Pool, setToken2Pool] = useState("");

  //Lp Token Supply
  const [lpTokenSupply, setLpTokenSupply] = useState("");
  //Confirm Model
  const [transaction, setTransaction] = useState(false);
  const handleTransaction = () => {
    setTransaction(true);
    waitModelHide();
    setAmountIn("");
    setAmountOut("");
    handleConfirmHide();
  };
  const handleTransactionHide = () => setTransaction(false);

  //Confirm Model
  const [confirm, setConfirm] = useState(false);
  const handleConfirm = () => {
    setConfirm(true);
    waitModelCall();
  };
  const handleConfirmHide = () => {
    setConfirm(false);
  };

  //Adding New Liquidity
  const [confirmLiquidity, setConfirmLiquidity] = useState(false);
  const handleConfirmLiqudity = () => {
    setConfirmLiquidity(true);
  };
  const handleConfirmLiquidityHide = () => {
    setConfirmLiquidity(false);
  };

  //Transaction Hash
  const [tx, setTx] = useState("");

  //Wait Model

  const [wait, setWait] = useState(false);
  const waitModel = () => {
    setWait(true);
    handleConfirmHide();
  };
  const waitModelHide = () => setWait(false);

  //Error Msg
  const [errorMsg, setErrorMsg] = useState("Something Went Wrong !!!!!");
  //Error Msg Model
  const [errShow, setErrShow] = useState(false);
  const handleErrorMsg = () => setErrShow(true);
  const handleErrorMsgHide = () => setErrShow(false);

  const waitModelCall = () => {
    if (confirm) {
      waitModel();
    }
  };

  //allPair
  const location = useLocation();
  console.log(location);
  const lpstate = location.state;
  let cookieListArr = [];
  //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++//

  //--Contract Intialization On first Load--//
  useEffect(() => {
    const tokenAdd = () => {
      setCoin1(lpstate.COIN1);
      setCoin2(lpstate.COIN2);
      setLbl1(lpstate.Label1);
      setLbl2(lpstate.Label2);
      setLogoImg1(lpstate.Logo1);
      setLogoImg2(lpstate.Logo2);
      setDecimal1(lpstate.Dec1);
      setDecimal2(lpstate.Dec2);
      setType1(lpstate.TYPE1);
      setType2(lpstate.TYPE2);
    };
    if (lpstate !== null) {
      tokenAdd();
    }
  }, [lpstate]);
  useEffect(() => {
    var web3 = new Web3(rpcUrl);
    const router_contract = new web3.eth.Contract(
      JSON.parse(PANCAKE_ROUTER_abi),
      PANCAKE_ROUTER
    );
    setRouterContract(router_contract);
    const factory_contract = new web3.eth.Contract(pair_abi, pair_contract);
    setFactoryContract(factory_contract);
  }, []);

  //++++++++++++++++++++++++++++++++++++++++++-------- Handling Events -------------+++++++++++++++++++++++++++++++++++++++++++++++++++//
  //-------------------------------------+ New Token List +---------------------------------------------------//
  const [checkedFlair, setCheckedFlair] = useState(true); // Flair Token List
  const [checkedSolana, setCheckedSolana] = useState(false); //Binance  Token List
  const [checkedPermissionPool, setCheckedPermissionPool] = useState(false); // Permission Pool Token List

  useEffect(() => {
    if (checkedFlair) {
      if (checkedSolana === false && checkedPermissionPool === false) {
        setDefaultOptions([...defaultoption]);
      } else if (checkedSolana) {
        setDefaultOptions([...defaultoption, ...SolanaTokenList]);
      } else if (checkedPermissionPool) {
        setDefaultOptions([...defaultoption, ...PermissionPoolTokenList]);
      }
    } else if (checkedSolana) {
      if (checkedFlair === false && checkedPermissionPool === false) {
        setDefaultOptions(SolanaTokenList);
      } else if (checkedFlair) {
        setDefaultOptions([...defaultoption, ...SolanaTokenList]);
      } else if (checkedPermissionPool) {
        setDefaultOptions([...SolanaTokenList, ...PermissionPoolTokenList]);
      }
    } else if (checkedPermissionPool) {
      if (checkedFlair === false && checkedSolana === false) {
        setDefaultOptions(PermissionPoolTokenList);
      } else if (checkedSolana) {
        setDefaultOptions([...SolanaTokenList, ...PermissionPoolTokenList]);
      } else if (checkedFlair) {
        setDefaultOptions([...defaultoption, ...PermissionPoolTokenList]);
      }
    } else if (checkedFlair && checkedSolana && checkedPermissionPool) {
      setDefaultOptions([
        ...defaultoption,
        ...SolanaTokenList,
        ...PermissionPoolTokenList,
      ]);
    } else {
    }
  }, [checkedFlair, checkedSolana, checkedPermissionPool]);

  //---------------------------------------------+ Adding New Token +--------------------------------------------------------------//
  //---Add New Token--//
  useEffect(() => {
    const addToken = async () => {
      try {
        if (defaultoption.length === 0 && searchToken !== "") {
          const web3Modal = new Web3Modal();
          const connection = await web3Modal.connect();
          const provider = new ethers.providers.Web3Provider(connection);
          const web3 = new Web3(provider);
          const new_token = web3.utils.toChecksumAddress(searchToken);
          const Contract_new_token = new ethers.Contract(
            new_token,
            ERC20ABI,
            provider
          );
          const decimal = await Contract_new_token.decimals();
          const Label = await Contract_new_token.symbol();
          const name = await Contract_new_token.name();
          const newTokenAdd = {
            value: new_token,
            name: name,
            label: Label,
            decimals: decimal,
            logo: tokenLogo,
            type: "token",
          };
          setNEwToken(newTokenAdd);
          let newTokenArr = [];
          newTokenArr.push({
            value: new_token,
            name: name,
            label: Label,
            decimals: decimal,
            logo: tokenLogo,
            type: "token",
            button: (
              <input
                type="button"
                value="import"
                style={{ backgraoundColor: "red" }}
                onClick={cookieAdd}
              />
            ),
          });
          setDefaultOptions(newTokenArr);
          const json_arr = JSON.stringify(newToken);
        }
      } catch (err) {
        console.log(err);
      }
    };
    addToken();
  });
  //Cookie Add
  const cookieAdd = () => {
    cookieAddList();
    let newCookieList = [];
    let cookieLen = document.cookie.indexOf("newTokenList=");
    let cookie = cookies.newTokenList;
    if (cookieLen === -1) {
      newCookieList.push(newToken);
    } else if (cookie == null) {
      newCookieList = [newToken];
    } else {
      newCookieList = [...cookie, newToken];
    }
    const data = [];
    for (var key in newCookieList) {
      if (newCookieList.hasOwnProperty(key)) {
        data.push(newCookieList[key]);
      }
    }
    for (let i = 0; i < data.length; i++) {
      console.log(data[i]);
    }
    setCookie("newTokenList", [...data], { path: "/", maxAge: 1000000 });
    cookieAddList();
  };

  const cookieAddList = () => {
    let cookie = document.cookie.indexOf("newTokenList=");
    if (cookie === -1) {
      setDefaultOptions(getBaseTokenLogoURLByTokenSymbol);
    } else {
      let arrList = [];
      let cookieNewList;
      cookieNewList = cookies.newTokenList;
      if (cookieNewList !== undefined) {
        let cookieArray = [...cookieNewList];
        setDefaultOptions([
          ...getBaseTokenLogoURLByTokenSymbol,
          ...cookieArray,
        ]);
      }
    }
  };

  useEffect(() => {
    cookieAddList();
  }, []);

  //------------------------------------------------Searching----------------------------------------------------//

  let filteredTokens = defaultoption.filter((token) => {
    return (
      token.name
        .toString()
        .toLowerCase()
        .includes(searchToken?.toString().toLowerCase()) ||
      token.value
        .toString()
        .toLowerCase()
        .includes(searchToken?.toString().toLowerCase())
    );
  });
  const handleSearchToken = (e) => {
    setSearch(e.target.value);
    if (e.target.value) {
      const searchTextLower = e.target.value.toLowerCase();
      const filteredResults = defaultoption.filter((item) => {
        const combinedString = `${item.name} ${item.value}`.toLowerCase();
        return (
          combinedString.includes(searchTextLower) ||
          combinedString.endsWith(searchTextLower)
        );
      });
      setDefaultOptions(filteredResults);
    } else {
      setDefaultOptions(getBaseTokenLogoURLByTokenSymbol);
    }
  };

  console.log(filteredTokens);

  useEffect(() => {
    const searchTokenList = () => {
      if (searchToken !== "") {
        setDefaultOptions(filteredTokens);
      } else {
        cookieAddList();
      }
    };
    searchTokenList();
  }, [searchToken]);
  const walletconnectmsg = (data) => {
    setWallectConnect(data);
  };
  useEffect(() => {
    setSearchToken("");
  }, [coin1, coin2]);
  useEffect(() => {
    cookieAddList();
  }, []);
  //----------------------------------------------+ Wallet Connect +--------------------------------------------//

  //-- Wallet Msg --//
  useEffect(() => {
    const provider = localStorage.getItem("provider");
    if (!address) {
      setWalletMsg("Connect Wallet");
    } else {
      if (amountIn == "" && amountOut == "") {
        setWalletMsg("Enter an Amount");
      } else if (amountOut == "") {
        setWalletMsg("Enter an Amount");
      } else {
        if (amountIn > parseFloat(coin1Balance)) {
          setWalletMsg(`Insufficient  ${lbl1} Balance`);
        } else if (amountOut > parseFloat(coin2Balance)) {
          setWalletMsg(`Insufficient  ${lbl2} Balance`);
        } else if (button1) {
          setWalletMsg("Waiting For Approval");
        } else if (button2) {
          setWalletMsg("Waiting For Approval");
        } else if (approved1 === true && approved2 === true) {
          setWalletMsg("Proceed");
        } else {
          setWalletMsg("Add Liquidity");
        }
      }
    }
  });

  //---------------------------------------+ AmountIn Change Handler +--------------------------------------//
  const [amtIn, setAmtIn] = useState("");
  const [amtOut, setAmtOut] = useState("");
  const amountEntered = (e) => {
    const value = e.target.value;
    setAmtIn(value);
    setAmountIn(value);
  };
  const handleChnageAmount = (e) => {
    if (coin1 === coin2 || lbl1 === lbl2) {
      setErrorMsg("👋, Both The Tokens Can't Be Same");
      handleErrorMsg();
    } else {
      const value = e.target.value;
      setAmtIn(value);
      setAmountIn(value);
    }
  };
  useEffect(() => {
    if (amtIn === "") {
      setAmountOut("");
    } else {
      getAmountsOut();
    }
  }, [amtIn, coin1, coin2]);
  const handleChnageAmount2 = (e) => {
    if (coin1 === coin2 || lbl1 === lbl2) {
      setErrorMsg("👋, Both The Tokens Can't Be Same");
      handleErrorMsg();
    } else {
      const value = e.target.value;
      setAmtOut(value);
      setAmountOut(value);
    }
  };

  useEffect(() => {
    if (amtOut === "") {
      setAmountIn("");
    } else {
      getAmoutsIn();
    }
  }, [amtOut]);
  useEffect(() => {
    if (type1 === "coin") {
      setCoin1(WBNBCOIN);
    } else if (type2 === "coin") {
      setCoin2(WBNBCOIN);
    }
  }, [amtIn, amtOut]);

  //----------------------------------------------+ Wallet Connect +---------------------------------------//
  const handleWallet = () => {
    handleConfirm();
  };
  const handleConfirmation = () => {
    waitModel();
    AddLiqudity();
  };
  //-- Wallet Popup --//
  const connectWallet = () => {
    if (walletMsg === "Add Liquidity") {
    } else {
      setWallet(true);
    }
  };
  //---------------------------------------------+ Max Button +------------------------------------------//
  const max1 = () => {
    setAmountIn(coin1Balance); //Max1
  };
  const max2 = () => {
    setAmountOut(coin2Balance); //Max2
  };
  //--------------------------------------+ Get Token Balance +-----------------------------------------//
  const getTokenBalance = async () => {
    if (type1 === "coin" || type2 === "coin") {
      CoinBalance();
    }
    if (!type1 || type1 === null || type1 === "" || type1 === undefined) {
      setType1("token");
    } else if (!type2 || type2 == null || type2 === "" || type2 === undefined) {
      setType2("token");
    }
    const web3Modal = new Web3Modal();
    const connection = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(connection);
    var web3 = new Web3(provider);
    const coin1Contract = new ethers.Contract(coin1, ERC20ABI, provider);
    const coin2Contract = new ethers.Contract(coin2, ERC20ABI, provider);
    const address = address;
    if (type1 === "token") {
      let coin1Balance = await coin1Contract.balanceOf(address);
      coin1Balance = ethers.utils.formatUnits(coin1Balance, decimal1);
      coin1Balance = parseFloat(coin1Balance).toFixed(2);
      setCoin1Balance(coin1Balance);
    }
    if (type2 === "token") {
      let coin2Balance = await coin2Contract.balanceOf(address);
      console.log("coin2Balance");
      console.log(coin2Balance);
      coin2Balance = ethers.utils.formatUnits(coin2Balance, decimal2);
      coin2Balance = parseFloat(coin2Balance).toFixed(2);
      setCoin2Balance(coin2Balance);
    }
  };

  const getTokenBalance1 = async () => {
    try {
      if (type1 === undefined || type1 === null || type1 === "") {
        if (coin1 != "") {
          setType1("token");
        }
      }
      if (type1 === "coin") {
        CoinBalance();
      }
      const web3Modal = new Web3Modal();
      const connection = await web3Modal.connect();
      const provider = new ethers.providers.Web3Provider(connection);
      var web3 = new Web3(provider);
      const coin1Contract = new ethers.Contract(coin1, ERC20ABI, provider);
      const coin2Contract = new ethers.Contract(coin2, ERC20ABI, provider);
      const address = address;
      if (type1 === "token") {
        if (lbl1 === "Select a currency") {
          setCoin1Balance("-");
        } else {
          let coin1Balance = await coin1Contract.balanceOf(address);
          coin1Balance = ethers.utils.formatUnits(coin1Balance, decimal1);
          coin1Balance = parseFloat(coin1Balance).toFixed(2);
          setCoin1Balance(coin1Balance);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  //Coin2 Balance calculation if Coin2 is a Token

  const getTokenBalance2 = async () => {
    try {
      if (type2 === undefined || type2 === null || type2 === "") {
        if (coin2 != "") {
          setType2("token");
        }
      }
      if (type2 === "coin") {
        CoinBalance();
      }
      const web3Modal = new Web3Modal();
      const connection = await web3Modal.connect();
      const provider = new ethers.providers.Web3Provider(connection);
      var web3 = new Web3(provider);
      const coin2Contract = new ethers.Contract(coin2, ERC20ABI, provider);
      const address = address;
      if (type2 === "token") {
        if (lbl2 === "Select a currency") {
          setCoin2Balance(0);
        } else {
          let coin2Balance = await coin2Contract.balanceOf(address);
          coin2Balance = ethers.utils.formatUnits(coin2Balance, decimal2);
          coin2Balance = parseFloat(coin2Balance).toFixed(2);
          setCoin2Balance(coin2Balance);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const CoinBalance = async () => {
    const web3Modal = new Web3Modal();
    const connection = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(connection);
    var web3 = new Web3(rpcUrl);
    const wbnb_contract = new ethers.Contract(WBNBCOIN, ERC20ABI, provider);
    var balance = await web3.eth.getBalance(address);
    let bal = ethers.utils.formatEther(balance);
    bal = parseFloat(bal).toFixed(2);
    if (type1 === "coin") {
      if (lbl1 === "Select a currency") {
        setCoin1Balance("-");
      } else {
        setCoin1Balance(bal);
      }
    }
    if (type2 === "coin") {
      if (lbl2 === "Select a currency") {
        setCoin2Balance(0);
      } else {
        setCoin2Balance(bal);
      }
    }
  };

  useEffect(() => {
    if (address) {
      getTokenBalance1();
    }
  }, [coin1, address, type1, lbl1]);

  useEffect(() => {
    if (address) {
      getTokenBalance2();
    }
  }, [coin2, address, type2, lbl2]);
  useEffect(() => {
    if (lbl1 === lbl2) {
      setLbl2("Select a currency");
      setCoin2("");
      setCoin2Balance(0);
    }
  }, [lbl1]);
  useEffect(() => {
    if (lbl2 === lbl1) {
      setLbl1("Select a currency");
      setCoin1("");
      setCoin1Balance("-");
    }
  }, [lbl2]);
  //--------------------------------------+ getAmountsOut +-------------------------------------------//
  const getAmountsOut = async () => {
    try {
      if (type1 === "coin") {
        setCoin1(WBNBCOIN);
      }
      if (type2 === "coin") {
        setCoin2(WBNBCOIN);
      }
      if (amountIn !== "") {
        if (lbl1 !== "Select a currency" && lbl2 !== "Select a currency") {
          let coin1amountIn = ethers.utils.parseUnits(amountIn, decimal1); //amount in for coin 1
          let amounts = await routerContract.methods
            .getAmountsOut(coin1amountIn, [coin1, coin2])
            .call();
          let AmountsCvt = parseFloat(
            amounts[1] / Math.pow(10, decimal2)
          ).toFixed(5); //amoutOut

          if (amountIn === "") {
            setAmountOut("");
          } else {
            setAmountOut(AmountsCvt); //AmountOut
            setAmountTokenVal(amounts[1]);
          }

          //--------------------------------------+ AmountOutMin +------------------------------------------//
          let AmountOutMinCal = amounts[1] - amounts[1] * (slippage / 100);
          let AmountOutMin = parseInt(AmountOutMinCal);
          //let AmountOutMinRec = parseFloat(AmountOutMinCal/Math.pow(10,decimal2)).toFixed(4); //amoutOut
          setAmoutOutMin(AmountOutMin);
        }
      }
    } catch (err) {
      console.log(err);
      setAmountTokenVal(0);
    }
  };

  useEffect(() => {
    if (
      errorMsg ===
      "Returned error: execution reverted: PancakeLibrary: INSUFFICIENT_INPUT_AMOUNT"
    ) {
      setErrorMsg(
        "Returned error: execution reverted: PancakeLibrary: INSUFFICIENT_INPUT_AMOUNT. Try To increase Slippage"
      );
      handleErrorMsg();
    }
  }, [errorMsg]);

  //--------------------------------------+ getAmountsIn +-------------------------------------------//

  const getAmoutsIn = async () => {
    try {
      if (type1 === "coin") {
        setCoin1(WBNBCOIN);
      } else if (type2 === "coin") {
        setCoin2(WBNBCOIN);
      }
      let coin2amountOut = ethers.utils.parseUnits(amountOut, decimal1); //amount in for coin 1
      let amounts = await routerContract.methods
        .getAmountsIn(coin2amountOut, [coin1, coin2])
        .call();
      let AmountIn = parseFloat(amounts[0] / Math.pow(10, decimal1)).toFixed(4); //amountIn
      if (amountOut === "") {
        setAmountIn("");
      } else {
        setAmountIn(AmountIn);
      } //AmountOut

      //--------------------------------------+ AmountOutMin +------------------------------------------//
      let AmountOutMinCal = amounts[1] - amounts[1] * (slippage / 100);
      let AmountOutMin = parseInt(AmountOutMinCal);
      setAmoutOutMin(AmountOutMin);
    } catch (err) {
      console.log(err);
      setErrorMsg(err.message ? err.message : "Something went wrong");
    }
  };

  //------------------------------------------+ Check Pair +---------------------------------------------//

  useEffect(() => {
    try {
      const checkPair = async () => {
        var web3 = new Web3(rpcUrl);
        const Pair_Smart_contract = new web3.eth.Contract(
          pair_abi,
          pair_contract
        );
        //--getting the Pair--//
        const pair = await Pair_Smart_contract.methods
          .getPair(coin1, coin2)
          .call();
        setPairAddress(pair);
        console.log(pair);
        const coin1Contract = new web3.eth.Contract(ERC20ABI, coin1);
        let pool1Blanace = await coin1Contract.methods.balanceOf(pair).call();
        pool1Blanace = ethers.utils.formatUnits(pool1Blanace, decimal1);

        pool1Blanace = parseFloat(pool1Blanace).toFixed(5);
        setToken1Pool(pool1Blanace);

        const coin2Contract = new web3.eth.Contract(
          JSON.parse(ERC20ABI),
          coin2
        );
        let pool2Blanace = await coin2Contract.methods.balanceOf(pair).call();
        pool2Blanace = ethers.utils.formatUnits(pool2Blanace, decimal2);
        pool2Blanace = parseFloat(pool2Blanace).toFixed(5);

        setToken2Pool(pool2Blanace);

        if (pair.toString().indexOf("0x0000000000000") > -1) {
          setLpTokenSupply("0");
        } else {
          const pairCoinContract = new web3.eth.Contract(
            JSON.parse(ERC20ABI),
            pair
          );
          let lpTokenSupply = await pairCoinContract.methods
            .totalSupply()
            .call();
          lpTokenSupply = ethers.utils.formatUnits(lpTokenSupply, 18);
          lpTokenSupply = parseFloat(lpTokenSupply).toFixed(5);
          setLpTokenSupply(lpTokenSupply);
        }
      };
      checkPair();
    } catch (err) {
      console.log(err);
      setErrorMsg(err.message);
      handleErrorMsg();
    }
  }, [coin1, coin2, pairAddress]);
  //-----------------------------------------------+ Get Slippage +--------------------------------------------------------------//

  const getSlippage = () => {
    if (balance === 0) {
      setSlippage(1.5);
    } else {
      const slippageTolerance = (amountIn / balance) * (slippage / 100);
      setSlippage(parseInt(slippageTolerance));
    }
  };

  useEffect(() => {
    getSlippage();
  }, [balance, amountIn, slippage]);

  //----------------------------------------------- Adding Liquidity ----------------------------------//
  //-------------------------------------+Handling Button Msg +-------------------------------------------//

  const [buttonMsg1, setButtonMsg1] = useState(`Approve ${lbl1}`);
  const [buttonMsg2, setButtonMsg2] = useState(`Approve ${lbl2}`);
  useEffect(() => {
    setButtonMsg1(`Approve ${lbl1}`);
    setButtonMsg2(`Approve ${lbl2}`);
  }, [lbl1, lbl2]);
  const [approved1, setApproved1] = useState(false);
  const [approved2, setApproved2] = useState(false);
  const [button1, setButton1] = useState(false);
  const [button2, setButton2] = useState(false);
  const handleButtonMsg = async () => {
    const web3Modal = new Web3Modal();
    const connection = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(connection);
    //let signer = wallet.connect(Provider);
    const signer = provider.getSigner();
    if (type1 === "coin") {
      setApproved1(true);
    }

    if (type1 === "token") {
      const coin1Contract = new ethers.Contract(coin1, ERC20ABI, provider);

      let allowance1 = await coin1Contract.allowance(address, PANCAKE_ROUTER);

      let totalSupply = await coin1Contract.totalSupply();
      console.log(ethers.utils.formatEther(allowance1));
      console.log(ethers.utils.formatEther(totalSupply));
      console.log();
      if (allowance1 < totalSupply) {
        setButton1(true);
        setApproved1(false);
      } else {
        setApproved1(true);
        setButton2(false);
      }
    }
    if (type2 === "coin") {
      setApproved2(true);
    }
    if (type2 === "token") {
      const coin2Contract = new ethers.Contract(coin2, ERC20ABI, provider);

      let allowance2 = await coin2Contract.allowance(address, PANCAKE_ROUTER);
      let totalSupply = await coin2Contract.totalSupply();
      console.log(ethers.utils.formatEther(allowance2));
      console.log(ethers.utils.formatEther(totalSupply));
      if (allowance2 < totalSupply) {
        setButton2(true);
      } else {
        setApproved2(true);
        setButton2(false);
      }
    }
  };

  const approval1 = async () => {
    setWalletMsg("Waiting For Approval");
    const web3Modal = new Web3Modal();
    const connection = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(connection);
    const signer = provider.getSigner();
    setButtonMsg1(`Approving ${lbl1} Please Wait`);
    const ContractApproval = new ethers.Contract(coin1, ERC20ABI, signer);

    const AmountIn = "11579208923731619542357098500868790785326998466";
    let approve = await ContractApproval.approve(PANCAKE_ROUTER, AmountIn);
    const result = await approve.wait();
    setApproved1(true);
    setButton1(false);
  };
  const approval2 = async () => {
    setWalletMsg("Waiting For Approval");
    const web3Modal = new Web3Modal();
    const connection = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(connection);
    //let signer = wallet.connect(Provider);
    const signer = provider.getSigner();
    setButtonMsg2(`Approving ${lbl2} Please Wait`);
    const ContractApproval = new ethers.Contract(coin2, ERC20ABI, signer);
    //const AmountIn = ethers.utils.parseUnits(amountOut, decimal1)
    const AmountIn = "11579208923731619542357098500868790785326998466";
    let approve = await ContractApproval.approve(PANCAKE_ROUTER, AmountIn);
    const result = await approve.wait();
    setApproved2(true);
    setButton2(false);
  };

  const AddLiqudity = async () => {
    waitModel();
    setWallet(false);
    const deadline = Date.now() + 1000 * 60 * 5; // 5min
    const address = address; //wallet address
    const to = address;
    const path = [coin1, coin2];
    try {
      //checking Amount In
      if (amountIn !== "" && amountIn !== undefined && amountIn > 0) {
        // checking Balance of token 1
        if (amountIn > parseFloat(coin1Balance)) {
          setWalletMsg(`Insuffient Funds ${lbl1} Balance`);
        }
        // checking Balance of Token 2
        else if (amountOut > parseFloat(coin2Balance)) {
          setWalletMsg(`Insuffient Funds ${lbl2} Balance`);
        }
        //--------+ If you have sufficient Amount of Both the tokens +-------//
        else {
          const web3Modal = new Web3Modal();
          const connection = await web3Modal.connect();
          const provider = new ethers.providers.Web3Provider(connection);
          // const wallet = new ethers.Wallet(Private_Key);
          const signer = provider.getSigner();
          var web3 = new Web3(new Web3(provider));
          const Pair_Smart_contract_CreatePair = new ethers.Contract(
            pair_contract,
            pair_abi,
            signer
          );
          const Pair_Smart_contract = new ethers.Contract(
            pair_contract,
            pair_abi,
            provider
          );
          if (type1 === "coin") {
            setCoin(WBNBCOIN);
          } else if (type2 === "coin") {
            setCoin2(WBNBCOIN);
          }
          const pair = await Pair_Smart_contract.getPair(coin1, coin2);
          const balance = await provider.getBalance(pair);
          setBalance(balance);
          setBalance(balance);
          //----+ Checking Pair Address +---//
          if (pair !== null && pair !== undefined) {
            if (pair.toString().indexOf("0x0000000000000") > -1) {
              console.log(`pairAddress ${pair} not detected. Auto restart`);
              //------+ Createpair new pair +-------//
              const Createpair =
                await Pair_Smart_contract_CreatePair.createPair(coin1, coin2);
            }
            //-------------------+ If Pair exist +--------------------//
            //-----------+ Check Token 1 +-----------//
            //----+ If first token is "BNB" +------//
            if (type1 === "coin" || type2 === "coin") {
              if (type1 === "coin") {
                //------+ If there is no Liquidity add inistial Liquidity (newly created pair)+------//
                if (amountTokenVal === 0) {
                  if (amountOut === "") {
                    setWalletMsg("Enter Amount");
                  }
                  setCoin(coin2);
                  addLiquidityETH(amountIn, coin2, amountOut, to, deadline);
                } else {
                  //------+ If there is  Liquidity of created pair +------//
                  setCoin(coin2);
                  addLiquidityETH(amountIn, coin2, amountOut, to, deadline);
                }
              }
              if (type2 === "coin") {
                //------+ If there is no Liquidity add inistial Liquidity (newly created pair)+------//
                if (amountTokenVal === 0) {
                  if (amountOut === "") {
                    setWalletMsg("Enter Amount");
                  }
                  setCoin(coin1);
                  addLiquidityETH(amountIn, coin1, amountOut, to, deadline);
                } else {
                  setCoin(coin1);
                  //------+ If there is  Liquidity of created pair +------//
                  addLiquidityETH(amountIn, coin1, amountOut, to, deadline);
                }
              }
            }
            //----+ If first token is NOT "WBNB" or "BNB" +------//
            else {
              //------+ If there is no Liquidity add inistial Liquidity (newly created pair) +------//
              if (amountTokenVal === 0) {
                let amountINParse = ethers.utils.parseUnits(amountIn, decimal1);
                let amountIN = amountINParse.toString();
                if (amountOut === "") {
                  setWalletMsg("Enter Amount");
                }
                addLiquidity(coin1, coin2, amountIn, amountOut, to, deadline);
              }
              //------+ If there is  Liquidity of created pair +------//
              else {
                addLiquidity(coin1, coin2, amountIn, amountOut, to, deadline);
              }
            }
          }
          //---+ If Pair is null +---//
          else {
            const Createpair = await Pair_Smart_contract_CreatePair.createPair(
              coin1,
              coin2
            );
          }
        }
      }
      //----+ Amount In Null or Empty or 0---//
      else {
        walletMsg("Enter Amount");
      }
    } catch (err) {
      waitModelHide();
      handleErrorMsg();
      toast.info(<div> Try to Change the slippage</div>, {
        autoClose: 5000,
        toastId: "info1",
      });
      console.log(err);
      setErrorMsg(err.message ? err.message : "Something went wrong");
      waitModelHide();
    }
  };
  //################################------------------Liquidity Calls--------------------#####################################//
  //-------------------------------+ addLiquidityEth +----------------------------------//
  const addLiquidityETH = async (amountIn, coin, amountOut, to, deadline) => {
    try {
      let provider = new ethers.providers.Web3Provider(window.ethereum);
      let signer = provider.getSigner();
      let contract = new ethers.Contract(
        PANCAKE_ROUTER,
        PANCAKE_ROUTER_abi,
        signer
      );
      let coin1amountIn = 0;
      let coin1amountOut = 0;
      if (type1 === "coin") {
        coin1amountIn = ethers.utils.parseUnits(amountIn, decimal1);
        coin1amountOut = ethers.utils.parseUnits(amountOut, decimal2);
      } else if (type2 === "coin") {
        coin1amountIn = ethers.utils.parseUnits(amountOut, decimal1);
        coin1amountOut = ethers.utils.parseUnits(amountIn, decimal2);
      }
      const path = [coin1, coin2];
      const txL = await contract.addLiquidityETH(
        coin,
        coin1amountOut,
        0,
        0,
        to,
        deadline,
        {
          value: coin1amountIn,
        }
      );
      const result = await txL.wait();
      console.log(`Tx-hash: ${result.hash}`);
      setTx(txL);
      waitModelHide();
      handleTransaction();
      getTokenBalance();
    } catch (err) {
      waitModelHide();
      handleErrorMsg();
      console.log(err);
      setErrorMsg(err.message ? err.message : "Something went wrong");
    }
  };
  // if AmountIn is blank
  useEffect(() => {
    if (amountIn === "") {
      setAmountOut("");
    }
  }, [amountIn]);
  //----------------------------------+ addLiquidity +-------------------------------------//
  const addLiquidity = async (
    coin1,
    coin2,
    amountIn,
    amountOut,
    to,
    deadline
  ) => {
    try {
      let provider = new ethers.providers.Web3Provider(window.ethereum);
      let signer = provider.getSigner();
      let contract = new ethers.Contract(
        PANCAKE_ROUTER,
        PANCAKE_ROUTER_abi,
        signer
      );
      let coin1amountIn = ethers.utils.parseUnits(amountIn, decimal1);
      let coin1amountOut = ethers.utils.parseUnits(
        amountOut.toString(),
        decimal2
      );
      if (coin1amountOut === "") {
        coin1amountOut = ethers.utils.parseUnits("0.1", decimal2);
      }
      if (coin1amountIn === "") {
        coin1amountIn = ethers.utils.parseUnits(amtIn, decimal1);
      }
      const txL = await contract.addLiquidity(
        coin1,
        coin2,
        coin1amountIn,
        coin1amountOut,
        0,
        0,
        to,
        deadline
      );
      const result = await txL.wait();
      setTx(txL);
      console.log(`Tx-hash: ${result.hash}`);
      waitModelHide();
      handleTransaction();
      setAmountIn("");
      setAmountOut("");
      getTokenBalance();
    } catch (err) {
      waitModelHide();
      handleErrorMsg();
      console.log(err);
      setErrorMsg(err.message ? err.message : "Something went wrong");
    }
  };
  const [walletConnected, setWallectConnected] = useState(false);
  const WalletConnect = (data) => {
    setWallectConnected(data);
  };
  useEffect(() => {
    if (amountIn === "") {
      setAmountOut("");
    }
  }, [amountIn]);

  //++++++++++++++++++++++++++++++++++++++++++++++++++++ JSX +++++++++++++++++++++++++++++++++++++++++++++++++//
  return (
    <>
      <InnerHeader />
      <ToastContainer />
      <div id="content">
        <section className="">
          <Container>
            <div className="app-text text-center">
              <div className="app-title">
                <span className="common-color common-title">
                  Hyperflair Liquidity
                </span>
              </div>
              <div className="app-subtitle">
                Add Liquidity to participate in Pools and Farm rewards
              </div>
            </div>
            <Row className="mt-3">
              <Col md={12} className="text-center">
                <div className="tabing">
                  <div className="tab-style">
                    <div className="tab-one">
                      <Link to="/swap">Swap</Link>
                    </div>
                    <div className="tab-two active">
                      <Link to="/liquidity">Liquidity</Link>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <div className="swap-page common-table">
          <div className="progress-modal-root">
            <Modal
              show={progress}
              onHide={handleProgress}
              className="progress-modal-root"
              centered
            >
              <Modal.Body>
                <div className="main-progress">
                  <div className="arrow"></div>
                  <div className="progress-text">
                    <span>
                      Displayed data will auto-refresh after 19 seconds. Click
                      this circle to update manually.
                    </span>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </div>
          <div className="info-modal-root">
            <Modal
              show={info}
              onHide={handleInfo}
              className="info-modal-root"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Program Addresses (DO NOT DEPOSIT)</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="swap-info">
                  <div className="info">
                    <div className="symbol">RAY</div>
                    <div className="address">
                      4k3Dyjzvzp8eMZ ... S5iCNLY3QrkX6R
                    </div>
                    <div className="action">
                      <i
                        aria-label="icon: copy"
                        tabindex="-1"
                        className="anticon anticon-copy"
                      >
                        <svg
                          viewBox="64 64 896 896"
                          data-icon="copy"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          aria-hidden="true"
                          focusable="false"
                          className=""
                        >
                          <path d="M832 64H296c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h496v688c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V96c0-17.7-14.3-32-32-32zM704 192H192c-17.7 0-32 14.3-32 32v530.7c0 8.5 3.4 16.6 9.4 22.6l173.3 173.3c2.2 2.2 4.7 4 7.4 5.5v1.9h4.2c3.5 1.3 7.2 2 11 2H704c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32zM350 856.2L263.9 770H350v86.2zM664 888H414V746c0-22.1-17.9-40-40-40H232V264h432v624z"></path>
                        </svg>
                      </i>
                      <a
                        href="https://solscan.io/token/4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R"
                        target="_blank"
                      >
                        <i
                          aria-label="icon: link"
                          className="anticon anticon-link"
                        >
                          <svg
                            viewBox="64 64 896 896"
                            data-icon="link"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true"
                            focusable="false"
                            className=""
                          >
                            <path d="M574 665.4a8.03 8.03 0 0 0-11.3 0L446.5 781.6c-53.8 53.8-144.6 59.5-204 0-59.5-59.5-53.8-150.2 0-204l116.2-116.2c3.1-3.1 3.1-8.2 0-11.3l-39.8-39.8a8.03 8.03 0 0 0-11.3 0L191.4 526.5c-84.6 84.6-84.6 221.5 0 306s221.5 84.6 306 0l116.2-116.2c3.1-3.1 3.1-8.2 0-11.3L574 665.4zm258.6-474c-84.6-84.6-221.5-84.6-306 0L410.3 307.6a8.03 8.03 0 0 0 0 11.3l39.7 39.7c3.1 3.1 8.2 3.1 11.3 0l116.2-116.2c53.8-53.8 144.6-59.5 204 0 59.5 59.5 53.8 150.2 0 204L665.3 562.6a8.03 8.03 0 0 0 0 11.3l39.8 39.8c3.1 3.1 8.2 3.1 11.3 0l116.2-116.2c84.5-84.6 84.5-221.5 0-306.1zM610.1 372.3a8.03 8.03 0 0 0-11.3 0L372.3 598.7a8.03 8.03 0 0 0 0 11.3l39.6 39.6c3.1 3.1 8.2 3.1 11.3 0l226.4-226.4c3.1-3.1 3.1-8.2 0-11.3l-39.5-39.6z"></path>
                          </svg>
                        </i>
                      </a>
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </div>
          <div className="setting-modal-root">
            <Modal
              show={setting}
              onHide={handleSetting}
              className="setting-modal-root"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Settings</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <h3>Slippage tolerance</h3>
                <div className="setting-button">
                  <button className="odd">0.1%</button>
                  <button className="active odd">0.1%</button>
                  <button className="odd">0.1%</button>
                  <input placeholder="0.1 %" className="odd last-btn" />
                </div>
              </Modal.Body>
            </Modal>
          </div>

          {/*Token 1 */}
          <TokenModal
            show={token1}
            hide={handleToken1}
            handleSearchToken={handleSearchToken}
            value={searchToken}
          >
            {defaultoption.map((item, index) => (
              <li
                className="token-info"
                onClick={() => {
                  setAmountIn("");
                  setLbl1(item.symbol);
                  setLogoImg1(item.logo);
                  setCoin1(item.value ? item.value : item.address);
                  setDecimal1(item.decimals);
                  setType1(item.type);
                  handleToken1();
                }}
                key={index}
              >
                <div className="token-img">
                  <img src={item.logo} style={{ borderRadius: "50%" }} />
                </div>
                <div className="token-text">
                  <span>{item.name}</span>
                </div>
                <sapn style={{ marginLeft: "6rem" }}>{item.button}</sapn>
              </li>
            ))}
          </TokenModal>
          {/* Token 2*/}
          <TokenModal
            show={token2}
            hide={handleToken2}
            handleSearchToken={handleSearch}
            value={searchToken}
          >
            {defaultoption.map((item, index) => (
              <li
                className="token-info"
                onClick={() => {
                  setAmountIn("");
                  setLbl2(item.symbol);
                  setLogoImg2(item.logo);
                  setCoin2(item.value ? item.value : item.address);
                  setDecimal2(item.decimals);
                  setType2(item.type);
                  handleToken2();
                }}
                key={index}
              >
                <div className="token-img">
                  <img src={item.logo} style={{ borderRadius: "50%" }} />
                </div>
                <div className="token-text">
                  <span>{item.name}</span>
                </div>
                <span style={{ marginLeft: "6rem" }}>{item.button}</span>
              </li>
            ))}
          </TokenModal>

          <div className="tokenlist-modal-root">
            <Modal
              show={tokenlist}
              onHide={handleTokenList}
              className="tokenlist-modal-root"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Select a token</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="switch-info">
                  <ul>
                    <li>
                      <div className="token-name">
                        <span>Flair Default List</span>
                      </div>
                      <div className="token-switch">
                        <BootstrapSwitchButton
                          onstyle="outline-dark"
                          offstyle="outline-light"
                          checked={checkedFlair}
                          size="xs"
                          width={10}
                          height={10}
                          boolean={false}
                          onChange={() => setCheckedFlair(!checkedFlair)}
                        />
                      </div>
                    </li>

                    <li>
                      <div className="token-name">
                        <span>Binance Token List</span>
                      </div>
                      <div className="token-switch">
                        <BootstrapSwitchButton
                          onstyle="outline-dark"
                          offstyle="outline-light"
                          checked={checkedSolana}
                          size="xs"
                          width={10}
                          height={10}
                          onChange={() => setCheckedSolana(!checkedSolana)}
                        />
                      </div>
                    </li>
                    <li>
                      <div className="token-name">
                        <span>Permissionless Pool Tokens </span>
                      </div>
                      <div className="token-switch">
                        <BootstrapSwitchButton
                          onstyle="outline-dark"
                          offstyle="outline-light"
                          checked={checkedPermissionPool}
                          size="xs"
                          width={10}
                          height={10}
                          onChange={() =>
                            setCheckedPermissionPool(!checkedPermissionPool)
                          }
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </Modal.Body>
            </Modal>
          </div>
          <Container>
            <Row>
              <Col md={12}>
                <div className="swap-box">
                  <div className="swap container">
                    <div className="card">
                      <div className="card-body">
                        <div className="coin-select">
                          <div className="label fs-container">
                            <span>From</span>
                            <span>{parseFloat(coin1Balance).toFixed(2)}</span>
                          </div>
                          <div className="coin-input">
                            <div className="main-input fs-container">
                              <button className="select-button fc-container">
                                <div
                                  className="fc-container"
                                  onClick={handletoken1}
                                >
                                  <div className="img-icon">
                                    <img src={logoimg1} />
                                  </div>
                                  <span>{lbl1}</span>
                                </div>
                                <i
                                  aria-label="icon: caret-down"
                                  className="anticon anticon-caret-down"
                                >
                                  <svg
                                    viewBox="0 0 1024 1024"
                                    data-icon="caret-down"
                                    width="1em"
                                    height="1em"
                                    fill="currentColor"
                                    aria-hidden="true"
                                    focusable="false"
                                    className=""
                                  >
                                    <path d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"></path>
                                  </svg>
                                </i>
                              </button>
                              <button className="btn-max" onClick={max1}>
                                Max
                              </button>
                              <input
                                inputMode="decimal"
                                autoComplete="off"
                                autoCorrect="off"
                                placeholder="0.00"
                                type="text"
                                pattern="^[0-9]*[.,]?[0-9]*$"
                                minLength="1"
                                maxLength="79"
                                spellCheck="false"
                                onChange={handleChnageAmount}
                                value={amountIn}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="change-side fc-container">
                          <div className="fc-container">
                            <i
                              aria-label="icon: plus"
                              className="anticon anticon-plus"
                            >
                              <svg
                                viewBox="64 64 896 896"
                                data-icon="plus"
                                width="1em"
                                height="1em"
                                fill="currentColor"
                                aria-hidden="true"
                                focusable="false"
                                className=""
                              >
                                <path d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8z"></path>
                                <path d="M176 474h672q8 0 8 8v60q0 8-8 8H176q-8 0-8-8v-60q0-8 8-8z"></path>
                              </svg>
                            </i>
                          </div>
                        </div>
                        <div className="coin-select">
                          <div className="label fs-container">
                            <span>To</span>
                            <span>{parseFloat(coin2Balance).toFixed(2)}</span>
                          </div>
                          <div className="coin-input">
                            <div className="main-input fs-container">
                              <button className="select-button fc-container">
                                <div
                                  className="fc-container"
                                  onClick={handletoken2}
                                >
                                  <div className="img-icon">
                                    <img src={logoimg2} />
                                  </div>
                                  <span>{lbl2}</span>
                                </div>
                                <i
                                  aria-label="icon: caret-down"
                                  className="anticon anticon-caret-down"
                                >
                                  <svg
                                    viewBox="0 0 1024 1024"
                                    data-icon="caret-down"
                                    width="1em"
                                    height="1em"
                                    fill="currentColor"
                                    aria-hidden="true"
                                    focusable="false"
                                    className=""
                                  >
                                    <path d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"></path>
                                  </svg>
                                </i>
                              </button>
                              <button className="btn-max" onClick={max2}>
                                Max
                              </button>
                              <input
                                inputMode="decimal"
                                autoComplete="off"
                                autoCorrect="off"
                                placeholder="0.00"
                                type="text"
                                pattern="^[0-9]*[.,]?[0-9]*$"
                                minLength="1"
                                maxLength="79"
                                spellCheck="false"
                                onChange={handleChnageAmount2}
                                value={amountOut}
                              />
                            </div>
                          </div>
                        </div>
                        {amountTokenVal === 0 ? (
                          <div className="price-info">
                            <div className="d-flex align-items-center justify-content-between">
                              <div
                                className="font-medium text-xs text-md-light"
                                style={{ padding: ".8rem" }}
                              >
                                You are the first liquidity provider. The ratio
                                of tokens you add will set the price of this
                                pool.
                                <br /> <br />
                                Once you are happy with the rate click supply to
                                review.
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {(coin1 !== "") & (coin2 !== "") ? (
                          <div className="price-info">
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="font-medium text-xs text-md-light">
                                Base
                              </div>
                              <div>{lbl1}</div>
                            </div>

                            <div className="d-flex align-items-center justify-content-between">
                              <div className="font-medium text-xs text-md-light">
                                Pool liquidity ({lbl1})
                              </div>
                              <div className="text-xs">
                                {token1Pool} {lbl1}
                              </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="font-medium text-xs text-md-light">
                                Pool liquidity ({lbl2})
                              </div>
                              <div className="text-xs">
                                {token2Pool} {lbl2}
                              </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="font-medium text-xs text-md-light">
                                LP supply
                              </div>
                              <div className="text-xs">{lpTokenSupply} LP</div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="font-medium text-xs text-md-light">
                                Addresses
                              </div>
                              <div>
                                {" "}
                                <a
                                  href={`https://bscscan.com/address/${pairAddress}`}
                                  target="_blank"
                                >
                                  BSC Scan
                                </a>
                              </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="font-medium text-xs">
                                Slippage Tolerance
                              </div>
                              <div className="text-xs coin-select text-center slippage-input">
                                <input
                                  inputMode="decimal"
                                  autoComplete="off"
                                  autoCorrect="off"
                                  placeholder="0.00"
                                  type="text"
                                  pattern="^[0-9]*[.,]?[0-9]*$"
                                  minLength="1"
                                  maxLength="79"
                                  spellCheck="false"
                                  style={{ width: "2.5em" }}
                                  value={slippage}
                                  className="text-center"
                                  onChange={(e) => setSlippage(e.target.value)}
                                />
                              </div>
                              <AiFillSetting
                                onClick={handleShowSlippage}
                                style={{ marginLeft: "-60px" }}
                              />
                              {/* Slippage */}
                              <SliipageModal
                                show={showSlippage}
                                hide={CloseSlippage}
                                setSlippage={setSlippage}
                                value={slippage}
                              />
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        {address ? (
                          <button
                            type="button"
                            className={
                              walletMsg == "Add Liquidity" ||
                              walletMsg == "Insufficient Liquidity Funds" ||
                              walletMsg == "Connect Wallet" ||
                              walletMsg == "Proceed"
                                ? "btn-primary"
                                : "btn-gray"
                            }
                            onClick={
                              walletMsg === "Connect Wallet"
                                ? connectWallet
                                : walletMsg === "Add Liquidity" ||
                                  walletMsg === "Insufficient Liquidity Funds"
                                ? handleButtonMsg
                                : walletMsg === "Proceed"
                                ? handleConfirm
                                : ""
                            }
                          >
                            {walletMsg}
                          </button>
                        ) : (
                          <Connect />
                        )}
                        {button1 === true ? (
                          <button
                            type="button"
                            className={
                              buttonMsg1 === `Approve ${lbl1}`
                                ? "btn-primary"
                                : "btn-gray"
                            }
                            onClick={approval1}
                          >
                            {buttonMsg1}
                          </button>
                        ) : (
                          ""
                        )}
                        {button2 === true ? (
                          <button
                            type="button"
                            className={
                              buttonMsg2 === `Approve ${lbl2}`
                                ? "btn-primary"
                                : "btn-gray"
                            }
                            onClick={approval2}
                          >
                            {buttonMsg2}
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div className="overlay"></div>

      <ConfirmModal
        show={confirm}
        hide={handleConfirmHide}
        action={AddLiqudity}
        Label1={lbl1}
        Label2={lbl2}
        AmountIn={amountIn}
        AmountOut={amountOut}
        ShowMsg="Confirm Supply"
      />

      <TransactionModal
        show={transaction}
        hide={handleTransactionHide}
        tx={tx}
      />

      <WaitingModal show={wait} hide={waitModelHide} />

      <ErrorModal
        show={errShow}
        hide={handleErrorMsgHide}
        errorMsg={errorMsg}
      />
    </>
  );
};
export default Liquidity;
