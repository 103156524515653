import { Modal } from 'react-bootstrap'
import React from 'react'
import './style.css'
export default function ConfirmModalSwap(props) {
    

    return (
        <>
        <Modal
        show={props.show}
        onHide={props.hide}
        className="token-modal-root"
        centered
        style={{bottomBorder:"none"}}
      >
      <Modal.Header className="pb-2" closeButton style={{borderBottom:"none"}}>
      <Modal.Title className="fs-5">Confirm Supply</Modal.Title>
    </Modal.Header>
        <Modal.Body style={{ height: "390px" }}>
        <div class="content border-top pb-5 mb-2" >
        <div class="inner-content-box mb-4 mt-3" style={{height:"280px"}}>
          <div class="text-center ">
            <lord-icon src="https://cdn.lordicon.com/nxaaasqe.json" trigger="loop" delay="1000"
            colors="primary:#ffffff,secondary:#38ef7d" style={{width:"150px",height:"150px"}} ></lord-icon>
            
            <p class="fw-bold m-0">{props.Label1} : <span id="" class="text-success">{props.AmountIn}</span></p>
            <p class="fw-bold m-0">{props.Label2} : <span id="" class="text-success">{props.AmountOut}</span></p>
            <p class="fw-bold m-0">Output is estimated. You will receive at least :
            <span id="" class="text-success"> {props.amountMinRec} </span>{props.Label2} or the transaction will revert.</p>
          </div>
        </div>
        <div className="mt-0 d-flex justify-content-between mb-4">
          <button type="button" name="button" class="btn btn-1" onClick={props.hide}>Cancel</button>
          <button type="button" name="button" class="btn btn-2"  onClick={props.action}>{props.ShowMsg}</button>
        </div>
      </div>
        </Modal.Body>
      </Modal>
        </>
    )
}
